import React, { useEffect } from "react";
import HeroBanner from "../../components/HeroBanner";
import openSourceBanner from "../../assests/Open_Source/mobile_app_development.mp4";
import DataDisplay from "../../components/DataDisplay";
import openSourceImg from "../../assests/Open_Source/Open_source_.gif";
import { Stack } from "react-bootstrap";

import time from "../../assests/timely_deliver.png";
import devProcess from "../../assests/Open_Source/Process_web_view.svg";
import backImg from "../../assests/back_img.png";
import icon1 from "../../assests/Open_Source/why_1.svg";
import icon2 from "../../assests/Open_Source/why_2.svg";
import icon3 from "../../assests/Open_Source/why_3.svg";
import icon4 from "../../assests/Open_Source/why_4.svg";
import icon5 from "../../assests/Open_Source/why_5.svg";

import WhatDoWeServe from "../../components/WhatDoWeServe";
import devProcessMobile from "../../assests/Open_Source/process_mobile_view.svg";
import "../../components/horizontalScrollSectionService.css";
import useWindowDimensions from "../../hooks/useWindowDimension";
import "./opensource.css";
import HorizontalScrollSectionService from "../../components/HorizontalScrollSectionService";

const OpenSource = () => {
  const { height, width } = useWindowDimensions();

  const services = [
    {
      icon: icon1,
      title: "Innovative Approach",
      description:
        "Hoffensoft leverages the latest open-source technologies to create cutting-edge solutions tailored to your specific needs.",
    },
    {
      icon: icon2,
      title: "Deep Community Integration",
      description:
        "Hoffensoft is deeply integrated into the open-source community, contributing to and collaborating with global developers.",
    },
    {
      icon: icon3,
      title: "User-Friendly Dashboards",
      description:
        "Hoffensoft ensures that their BI tools are easy to use, enabling users at all levels of the organization to access and understand critical data.",
    },
    {
      icon: icon4,
      title: "Transparent Development Process",
      description:
        "Hoffensoft’s open-source projects are characterized by transparency, allowing clients to see every step of the development process.",
    },
    {
      icon: icon5,
      title: "Tailored Customization",
      description:
        "Hoffensoft excels at customizing open-source solutions to fit your unique business processes and goals.",
    },
    {
      icon: time,
      title: "Long Term Relationship",
      description:
        "Hoffensoft believes that technology as an instrument can be employed to scale new heights, and hence has focused on developing scalable solutions.",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const bannerTitle = "Open Source";
  const bannerDisc =
    "Open source thrives on shared knowledge and collaboration, moving beyond the idea of a solitary genius. It’s a powerful approach that drives technological progress and business growth.";

  const heading = "Empower Your Enterprise with Open Source Software ";
  const description =
    "Open source software provides enterprises with greater control over application functionalities, enabling tailored solutions that meet specific business needs. It offers seamless interoperability across diverse operational environments, allowing teams to achieve speed, cost-effectiveness, flexibility, and robust information security. Our expertise spans both backend and frontend development across leading open source platforms like Java, PHP, React JS, and Node JS.";
  const data = {
    "Operational Areas": {
      title: "Operational Areas",
      description: {
        content:
          "Hoffensoft delivers open source solutions designed for ultimate flexibility and efficiency. Our robust frameworks and tools support Agile development across various domains, including:",
        list: [
          "E-commerce and educational platforms",
          "E-ticketing systems",
          "Shopping carts and secure payment gateways",
          "Voice applications powered by Node.js",
          "Custom plug-ins developed in Java",
          "Comprehensive Content Management Systems (CMS)",
          "Advanced Document Management Systems (DMS)",
          "Rigorous software testing and quality control",
        ],
      },
    },
    "Stability with Open Source": {
      title: "Stability with Open Source",
      description:
        "Hoffensoft’s open source solutions are designed to provide your business with unmatched stability and scalability. Our solutions offer a solid foundation for your operations, ensuring smooth performance and the flexibility to scale as your needs evolve. Whether you’re looking to enhance system reliability or expand your capabilities, our open source technologies deliver the resilience and growth potential you need to stay ahead in a competitive market.",
    },
    Services: {
      title: "Services",
      description:
        "Our Hybrid app development team creates applications with a unified, high-performance UI that adapts seamlessly across all devices, offering both online and offline functionality to keep your business connected. Our Salesforce solutions streamline business operations and enhance customer management, driving efficiency and growth for your enterprise.",
    },
  };

  return (
    <>
      <HeroBanner
        video={openSourceBanner}
        title={bannerTitle}
        description={bannerDisc}
        button={true}
      />
      <section className="">
        <DataDisplay
          title={heading}
          description={description}
          // image={openSourceImg}
          image={"/assets/svg/open_source.svg"}
        />
      </section>
      <HorizontalScrollSectionService
        services={services}
        whyChooseTitle="Why choose Hoffensoft for Open Source Services"
      />

      <div className="py-5">
        <Stack>
          <h2 className="sub-heading sub-heading-styling text-center">
            Our OpenSource Process
          </h2>
          <p
            className="paragraph mt-2 mt-lg-4 mt-md-4 mt-sm-2 text-center opensource-process-desc"
            style={{ color: "#4B4B4B" }}
          >
            We ensure our clients experience a transparent and seamless process
            throughout the development journey
          </p>
        </Stack>
        <img
          src={width > 730 ? devProcess : devProcessMobile}
          className="img-fluid dev-process-img"
          alt={width > 700 ? "web-dev-process" : "mobile-dev-process"}
        />
      </div>
      <WhatDoWeServe
        title="What Do We Serve ?"
        description="Open source software gives enterprises an opportunity to have more control over the functionalities that they wish to derive from applications."
        data={data}
        bgImg={backImg}
        position="top center"
      />
    </>
  );
};

export default OpenSource;
