import React, { useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import SearchBox from "./SearchBox";
import search_icon from "../assests/search_icon.svg"

const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 239px;
  @media (max-width: 800px) {
    width: 100%;
    height:280px
  }
`;


// const VideoBackground = styled.video`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;

//   @media (max-width: 800px) {
//     width: 100%;
//   }

  
// `;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(132, 71, 208, 0.8); 
`;
const BannerContent = styled(Container)`
  position: absolute;
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%); 
  text-align: left; 
  color: #fff; 
  z-index: 1;

  @media (max-width: 800px) {
    top: 100px;
    margin-top: 30px;
  }


  @media (min-width: 320px) and (max-width: 430px) {
    top: 120px;
  }
`;
const BannerHeading = styled.h1`
  margin-bottom: 16px; 
  text-align: left;


  @media (min-width: 320px) and (max-width: 430px) {
    text-align: center;
  }
`;
const BannerDescription = styled.p`

  text-align: left;
  max-width: 700px;
  @media (max-width: 800px) {
  
  }

  @media (min-width: 320px) and (max-width: 430px) {
    text-align: center;
  }
`;
const  BlogBanner = ({ video, title, description, onSearch }) => {
  const [searchQuery, setSearchQuery] = useState("");
  return (
    <BannerContainer>
      {/* <VideoBackground autoPlay muted loop>
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </VideoBackground> */}
      <Overlay />
      <BannerContent>
        <Row>
          <Col lg={7}  md={12} sm={12}>
            <BannerHeading className="heading  text-lg-start text-md-center text-sm-center">
              {title}
            </BannerHeading>
            <BannerDescription className="paragraph  text-lg-start text-md-center text-sm-center">
              {description}
            </BannerDescription>
          </Col>
          <Col lg={5} md={12} sm={12} className="align-self-lg-center d-md-flex align-items-md-center justify-content-md-center">
            <SearchBox
              icon={search_icon}
              placeholder='Search blog'
              borderRadius={true}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onClick={onSearch}
            />
          </Col>
        </Row>
      </BannerContent>
    </BannerContainer>
  );
};
export default BlogBanner;