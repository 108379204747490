import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import "./projectOverview.css";
import { Col, Container, Row, Stack } from "react-bootstrap";
import CustomButton from "../components/CustomButton";

import vector from "../assests/Project_overview/Vector.svg";
import vector1 from "../assests/Project_overview/vector1.svg";
import arrow from "../assests/Project_overview/arrow.webp";
import star from "../assests/Project_overview/rotate.svg"

import mg_banner from "../assests/Project_overview/mg.webp";
import mgw_banner from "../assests/Project_overview/mgw.webp";
import truckbee_banner from "../assests/Project_overview/truckbee.webp";
import paytronix_banner from "../assests/Project_overview/payronix.webp"

import Android from "../assests/Project_overview/android.svg";
import IOS from "../assests/Project_overview/iOS.svg";
import reactNative from "../assests/Project_overview/React.svg";
import flutter from "../assests/Project_overview/flutter.svg";

import mg_logo from "../assests/Project_overview/mg_logo.webp";
import truckbee_logo from "../assests/Project_overview/truckbee_logo.png";
import paytronix_logo from "../assests/Project_overview/paytronix_logo.png";

import truckbee_web from "../assests/Project_overview/truckbee_web.webp";
import mg_web from "../assests/Project_overview/mg_web.webp";
import jkcafe_web from "../assests/Project_overview/jkcafe_web.webp";

import project_truckbee from "../assests/projects_worked/truck_bee.webp";
import project_siruthuli from "../assests/projects_worked/siruthuli.webp";
import project_mgm from "../assests/projects_worked/mgm.webp";
import project_jkcafe from "../assests/projects_worked/jk_cafe.webp";
import project_mg_web from "../assests/projects_worked/mg-web.png";

const projectsList = [
  {
    id: "truck-bee",
    name: "Truckbee",
    desc: "Premium Car Transport, Where Your Memories Ride in Style.",
    img: project_truckbee,
    link: "project-overview/truck-bee",
  },
  {
    id: "mgw",
    name: "Mg Web",
    desc: "Your Go-To Workspace Partner",
    img: project_mg_web,
    link: "project-overview/mgw",
  },
  {
    id: "paytronix",
    name: "Paytronix",
    desc: "Transforming Client Engagement into Unforgettable Guest Experiences.",
    img: project_jkcafe,
    link: "project-overview/paytronix",
  },
  {
    id: "mg",
    name: "Mg",
    desc: "Your Go-To Workspace Partner",
    img: project_mgm,
    link: "project-overview/mg",
  },
];

const clients = [
  {
    id: "mg",
    header_img: mg_banner,
    header_icon: mg_logo,
    header_title: "Be Right Here",
    header_desc:
      "App that enables users to seamlessly reserve, manage, and optimize workplace resources and meeting rooms.",
    platform: [
      { name: "Android", icon: Android },
      { name: "IOS", icon: IOS },
    ],
    technology: [
      { name: "Flutter", icon: flutter },
      { name: "React Native", icon: reactNative },
    ],
    overview: ` BeRightHere offers expansive meeting space and workspace solutions in over 300 cities across India, catering to enterprises, global firms, and startups. Whether you need to schedule meetings, make pitches, seat thousands of employees, or just need a hot desk, our solutions are tailored to meet your needs. We help businesses adapt to the hybrid future of work by providing secure, accessible, and flexible workspaces. Simplify your workspace requirements with BeRightHere.com and future-proof your business.`,
    banner_img: mg_web,
  },
  {
    id: "truck-bee",
    header_img: truckbee_banner,
    header_icon: truckbee_logo,
    header_title: "Truckbee",
    header_desc:
      "Effortlessly move your car from one city to another with TruckBee. Reliable, safe, and hassle-free transportation at your fingertips.",
    platform: [
      { name: "Android", icon: Android },
      { name: "IOS", icon: IOS },
    ],
    technology: [
      { name: "Flutter", icon: flutter },
      { name: "React Native", icon: reactNative },
    ],
    overview: `At TruckBee, we understand your car is more than just a vehicle—it’s a cherished part of your life. We are dedicated to delivering your car with unmatched care and precision, ensuring a stress-free moving experience. Our secure processes guarantee that your car is in the best hands, with no added miles and tamper-proof key security. For regional moves, we use open trailers for efficiency, while our premium enclosed carriers provide ultimate protection for long-haul journeys. TruckBee redefines convenience and security in vehicle relocation, ensuring your car arrives scratch-free and ready for its next adventure.`,
    banner_img: truckbee_web,
  },
  {
    id: "paytronix",
    header_img: paytronix_banner,
    header_icon: paytronix_logo,
    header_title: "JK Cafee",
    header_desc:
      "Provide top-notch food delivery applications to your sub-clients with Paytonix. Streamline operations, enhance customer experience, and grow your business effortlessly.",
    platform: [
      { name: "Android", icon: Android },
      { name: "IOS", icon: IOS },
    ],
    technology: [
      { name: "Flutter", icon: flutter },
      { name: "React Native", icon: reactNative },
    ],
    overview: `JK Cafe is a cutting-edge mobile app built in Java and Swift, designed to transform the way you order food. Whether you're at home, at work, or on the go, JK Cafe makes it easy to satisfy your cravings with just a few taps. The app features a user-friendly interface that allows you to browse an extensive menu, customize your orders to your liking, and enjoy seamless delivery. With real-time order tracking, secure payment options, and exclusive deals available only through the app, JK Cafe ensures a convenient and enjoyable food ordering experience. Additionally, the quick reorder feature lets you easily reorder your favorite meals, making the process even faster. Experience the ease and convenience of ordering delicious food anytime, anywhere with the JK Cafe mobile app.`,
    banner_img: jkcafe_web,
  },
  {
    id: "mgw",
    header_img: mgw_banner,
    header_icon: mg_logo,
    header_title: "MG Web",
    header_desc:
      "App that enables users to seamlessly reserve, manage, and optimize workplace resources and meeting rooms.",
    platform: [
      { name: "Android", icon: Android },
      { name: "IOS", icon: IOS },
    ],
    technology: [
      { name: "Flutter", icon: flutter },
      { name: "React Native", icon: reactNative },
    ],
    overview: ` "Be Right Here" revolutionizes real estate transactions
          with intuitive features like property search, virtual tours, and
          secure transactions. Tailored for buyers, sellers, agents, and
          renters, the app simplifies listing, viewing, and managing properties
          on smartphones, enhancing efficiency in the market. It facilitates
          seamless interactions and transactions, empowering users to find,
          evaluate, and transact properties effortlessly, anytime, anywhere.`,
    banner_img: mg_web,
  },
];

const ProjectOverview = () => {
  const { project } = useParams();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([...projectsList]);
  const [client, setClient] = useState(clients[0]);

  useEffect(() => {
    console.log("project", project);
    console.log("project", clients);
    window.scrollTo(0, 0);
    const filteredClient = clients?.find((item) => item?.id == project);
    console.log(filteredClient);
    const filtered = projectsList?.filter((item) => item?.id !== project);
    setClient(filteredClient);
    setProjects(filtered);
  }, [project]);

  const handleNaviagte = (path) => navigate(`/${path}`);

  return (
    <div className="project-overview">
      <Container fluid className={`banner_container ${project}`}>
        <Container className="px-3 px-lg-0 px-md-0 px-sm-3">
          <Row>
            <Col
              lg={5}
              md={6}
              sm={6}
              className="align-self-center align-self-lg-center align-self-md-center"
            >
              <Stack direction="veritical">
                <img
                  className="client-logo"
                  src={client?.header_icon}
                  alt={project}
                />
                <h2 className="sub-heading text-start">
                  {client?.header_title}
                </h2>
                <p className="pb-4 mb-0">{client?.header_desc}</p>
                <h4 className="">Platform developed</h4>
                <div className="platforms d-flex align-items-center">
                  {client?.platform?.map((platform) => (
                    <div
                      key={platform?.name}
                      className="platform-container me-3 d-flex align-items-center"
                    >
                      <img
                        className="me-2"
                        src={platform?.icon}
                        alt="android"
                      />
                      <p className="pe-4 border-end mb-0 platform-name">
                        {platform?.name}
                      </p>
                    </div>
                  ))}
                </div>
              </Stack>
            </Col>

            <Col lg={7} md={6} sm={12} className="banner_col mt-5 mt-lg-0 mt-md-0 mt-sm-5">
              <div className="banner_vector">
                <img className="vector d-block" src={vector} />
                <img className="vector1 d-block" src={vector1} />
                <img className="arrow d-block" src={arrow} />
                <img className="star d-block" src={star} />
                <img
                  className="banner_img d-block mx-auto"
                  src={client?.header_img}
                  alt=""
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="py-5 px-3 px-lg-0 px-md-0 px-sm-3">
        <h3 className="text-center text-lg-start text-md-start text-sm-center sub-heading">
          Overview
        </h3>
        <p className="paragraph text-center text-lg-start text-md-start text-sm-center">
          {client?.overview}
        </p>
        <div className="technologies d-flex flex-column flex-lg-row flex-md-row flex-sm-column align-items-center">
          <h5 className="m-0 pb-3 pb-lg-0 pb-md-0 pb-sm-3">Technology</h5>
          <div className="d-flex flex-column flex-lg-row flex-md-row flex-sm-column justify-content-center">
            {client?.technology?.map((tech) => (
              <div
                key={tech?.name}
                className="tech-container ms-0 ms-lg-3 ms-md-3 ms-sm-0 d-flex align-items-center mb-3 mb-lg-0 mb-md-0 mb-sm-3"
              >
                <img className="me-2" src={tech?.icon} alt="flutter" />
                <p className="tech-name mb-0 pe-0 pe-lg-4 pe-md-4 pe-sm-4 border-end">{tech?.name}</p>
              </div>
            ))}
          </div>
        </div>
      </Container>
      <Container fluid className="web-banner-container p-0">
        <img className="web-banner" src={client?.banner_img} alt={project} />
      </Container>
      <Container className="py-5 projects-container">
        <h2 className="sub-heading mb-5">Want to Know More?</h2>
        <Row className="our_projects gx-lg-4 gx-md-4 gy-5">
          {projects?.map((project) => (
            <Col
              lg={4}
              md={6}
              sm={12}
              key={project?.id}
              onClick={() => handleNaviagte(project?.link)}
              className="mb-lg-4 mx-auto d-flex flex-column justify-content-center align-items-center align-items-sm-center"
            >
              <div className="card mb-4">
                <Link className="card-link"><img className="" src={project?.img} alt={project?.name} /></Link>
              </div>
              <div className="card-details">
                <p className="card-title mb-2">{project?.name}</p>
                <p className="card-para m-0">{project?.desc}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <Container>
        <div className="proposal-container d-flex flex-column align-items-center justify-content-center">
          <h2 className="sub-heading pb-5">
            Let’s Transform your business for a change that matters
          </h2>
          <CustomButton
            variant="#8447d0" // Purple color
            value="Get a Proposal"
            onClick={() => {
              navigate("/support");
            }}
            shape="round"
            outlined={false} // Filled button
            color="#fff"
          />
        </div>
      </Container>
    </div>
  );
};

export default ProjectOverview;
