import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { ReactComponent as ArrowIcon } from "../assests/right-arrow-svgrepo-com.svg";
import './TabNavigation.css'

const Containers = styled.div`
  padding: 8%;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  position: relative;
  margin-bottom: 25px;

  @media (min-width: 320px) and (max-width: 425px){
    margin: 0% 2%;
  }

  @media (min-width:440px) and (max-width:800px){
    padding: 10%;
  }
`;

const TabList = styled.div`
  padding-right: 23px;
`;

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 22px; 

  @media screen and (min-width:320px) and (max-width:375px){
    margin-bottom: 30px !important;
  }

  @media screen and (min-width:425px){
    margin-bottom: 44px;
  }
`;

const Tab = styled.div`
  cursor: pointer;
  background-color: ${(props) => (props.active ? "white" : "white")};
  color: ${(props) => (props.active ? "#8447d0" : "#7A7A7A")};
  &:hover {
    background-color: white;
  }
`;


const Content = styled.div`

  .unorder{
    margin: 0px !important;
  }

`;

const StyledArrowIcon = styled(ArrowIcon)`
  width: 24px;
  height: 24px;
  stroke: ${(props) => (props.active ? "#6200EA" : "#7A7A7A")};
  margin-right: 8px;
`;

const TabNavigation = ({ data }) => {
  const [activeTab, setActiveTab] = useState(Object.keys(data)[0]);
  return (
    <Containers className="">
      <Row className="align-items-start">
        <Col lg={4} md={4} sm={12}>
          <TabList>
            {Object.keys(data).map((tab) => (
              <div className="row" key={tab}>
                <TabContainer key={tab} className="">
                  <div className="">
                    <StyledArrowIcon
                      className={tab === activeTab ? "active" : ""}
                    />
                  </div>
                  <Tab
                    className=""
                    active={tab === activeTab}
                    onClick={() => setActiveTab(tab)}
                  >
                    <p className="m-0 card-title"  >{tab}</p>
                  </Tab>
                </TabContainer>

                {/* <div className='col-4 my-auto'>
                                    <div className='mx-auto'>
                                        {tab === activeTab && <Line />}
                                    </div>
                                </div> */}
              </div>
            ))}
          </TabList>
        </Col>
        <Col lg={8} md={8}  className="content-container">
          <Content>
            {data[activeTab]?.image && (
              <img
                className="img-fluid services-mobile-img"
                src={data[activeTab]?.image}
                alt={`${data[activeTab]?.title} Icon`}
              />
            )}
            <h2 className="accordion-heading tab-nav-heading">{data[activeTab]?.title}</h2>
            {typeof data[activeTab]?.description === "string" ? (
              <p
                className="accordion-para"
              >
                {data[activeTab]?.description}
              </p>
            ) : (
              <>
                <p
                  className="accordion-para"
                >
                  {data[activeTab]?.description?.content}
                </p>
                <ul
                  className="unorder accordion-para"
                  style={{ fontSize: "20px", lineHeight: "40px" }}
                >
                  {data[activeTab]?.description?.list?.map((item, index) =>
                    Array.isArray(item) ? (
                      <ul className="unorder accordion-para" key={index} style={{}}>
                        {item.map((subItem, subIndex) => (
                          <li
                            className="accordion-para"
                            key={subIndex}
                            style={{ position: "relative", marginLeft: "1em" }}
                          >
                            {/* <span style={{ position: 'absolute', left: 0 }}>-</span> */}
                            {subItem}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <li
                        className="accordion-para"
                        key={index}
                        style={{ position: "relative", marginLeft: "1em" }}
                      >
                        {/* <span style={{ position: 'absolute', left: 0 }}>-</span> */}
                        {item}
                      </li>
                    )
                  )}
                </ul>
              </>
            )}
          </Content>
        </Col>
      </Row>
    </Containers>
  );
};

export default TabNavigation;