import React, { useRef, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "react-bootstrap";
import CustomButton from "../components/CustomButton";
import careerVideo from "../assests/Careers/careers.mp4";
import styled from "styled-components";
import HeroBanner from "../components/HeroBanner";
import "./whyus.css";
import Slider from "react-slick";
import quotemark from "../assests/Careers/quote_mark.svg";

import grpimage from "../assests/Careers/group_image.webp";

import prabu from "../assests/Careers/prabhu.webp";
import prabha from "../assests/Careers/praba.webp";
import kalai from "../assests/Careers/kalai.webp";
import shamini from "../assests/Careers/shamini.webp";
import karthick from "../assests/Careers/karthik.webp";
import sathya from "../assests/Careers/sathya.webp";
import parthiban from "../assests/Careers/parthiban.webp";
import roohi from "../assests/Careers/roohi.webp";

import prevIcon from "../assests/left_slider.png";
import nextIcon from "../assests/right_slider.png";

import mobile_career_bottom_banner from "../assests/Careers/mobile_carrier_bottom_banner.webp";
import web_career_bottom_banner from "../assests/Careers/web_carrier_bottom_banner.webp";

import { useNavigate } from "react-router-dom";

import useWindowDimensions from "../hooks/useWindowDimension";

const Heading = styled.h2`
  color: #000;
  margin-bottom: 10px;
  & span {
    color: #8447d0;
  }
`;
const Content = styled.p`
  text-align: center;
  margin: auto 25px;
`;

const WhyUs = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const managers = [
    {
      name: "Prabhakaran mani",
      position: "Project Manager",
      image: prabha,
      content:
        "At Hoffensoft, we prioritize excellence and client satisfaction through innovative, high-quality software solutions. Our collaborative work culture and agile methodologies ensure timely and efficient project delivery. We take pride in being a trusted partner, dedicated to our clients' success.",
    },
    {
      name: "Sathyaram Thangavel",
      position: "Project Manager",
      image: sathya,
      content:"At our company, we focus on outstanding performance and customer fulfillment by leveraging cutting-edge, top-notch software solutions. Our teamwork-oriented environment and flexible approaches guarantee prompt and effective project execution. We are proud to be a reliable collaborator, committed to our clients’ achievements."
    },
    {
      name: "Roohi R",
      position: "CFO",
      image: roohi,
      content:"With unwavering confidence, I am proud to champion Hoffensoft’s exceptional services. Our dedicated team consistently delivers innovative solutions tailored to our clients' needs, fostering growth and efficiency. We prioritize transparency and collaboration, forming strong partnerships and exceeding expectations. As industry leaders, we leverage cutting-edge technology to empower our clients' success. Thrilled to be part of such a forward-thinking organization, I eagerly anticipate what the future holds for us."
    },

    // {
    //   name: "Parthiban Subbaiyan",
    //   position: "Development Manager",
    //   image: parthiban,
    //   content:
    //     "Hoffensoft's work culture is one of the best I've experienced. The friendly atmosphere encourages teamwork and collaboration. Our team focuses on the latest industry trends, keeping our projects fresh and innovative. We consistently deliver top-quality apps that exceed client expectations.",
    // },
    // {
    //   name: "Kalaiselvan Balusamy",
    //   position: "Technical Lead",
    //   image: kalai,
    //   content:
    //     "Starting my career as an Android Developer and progressing to a Tech Lead has been an incredible journey, made possible by the unwavering support of my team and management. The friendly work environment here has allowed me to stay focused on my work and personal growth, motivating me to create a better work environment for my team as well.",
    // },
    // {
    //   name: "Shamini Ravi",
    //   position: "Technical Manager",
    //   image: shamini,
    //   content:
    //     "As a Business Analyst, I value data-driven decisions made with due diligence. Hoffensoft has given me the freedom to explore new methods and practices, considering all stakeholders' interests. This flexibility enables informed decision-making, enhancing project success. Working with Hoffensoft has not only allowed me to grow professionally but has also contributed significantly to the success of our initiatives, making it a truly rewarding experience.",
    // },
    // {
    //   name: "Karthick Tamilselvan",
    //   position: "Configuration Manager",
    //   image: karthick,
    //   content:
    //     "I am proud to be part of a company that seamlessly blends technological advancement with compassion and community service. Hoffensoft equally prioritizes customer growth, employee comfort, and social responsibility, creating a truly exceptional workplace.",
    // },

  ];
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sliderRef = useRef(null);

  const navigate = useNavigate();

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const next = () => {
    sliderRef.current.slickNext();
  };

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentIndex(newIndex);
    },
  };

  const handleOpenPosition = () => {
    navigate("/careers");
  };

  return (
    <>
      <HeroBanner
        video={careerVideo}
        title={"Working at Hoffensoft"}
        description={
          "At Hoffensoft, we are people-centric and highly collaborative. We prioritize long-term success, believing we’re in a marathon, not a sprint. Join us for a fulfilling journey where your growth and our shared vision come first."
        }
      />
      <section>
        <Container fluid>
          <Row className="">
            <Col lg={12} md={12} className="img-frame">
              <div className="img-container">
                <img
                  src={grpimage}
                  className="img-fluid rounded-5"
                  alt="grp-img"
                />
                <div className="img-btn">
                  <CustomButton
                    outlined={false}
                    shape={"round"}
                    value="See Open Positions"
                    variant={"#8447D0"}
                    onClick={handleOpenPosition}
                    color="white"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="lr-padding">
          <Row className="why-should-join">
            <Col
              sm={12}
              className="mt-5 mt-lg-5 mt-md-5 mt-sm-3 mb-3 mb-lg-5 mb-md-3 mb-sm-0 mx-md-auto p-0"
            >
              <Heading className="heading text-center">
                Why join <span>Hoffensoft?</span>
              </Heading>
              <Content className="paragraph mb-4">
                Collaboration, mentoring, growth, and harmony Join us and watch
                your career soar!
              </Content>
            </Col>
            <Col lg={6} md={12} sm={12} className="">
              <img
                src={prabu}
                className="d-block mx-md-auto rounded prabu-img"
                alt="prabhu.jpg"
              />
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              className="my-auto paragraph  mt-3 mt-lg-0 mt-md-4 mt-sm-0 text-start content d-flex flex-column-reverse flex-lg-column flex-md-column-reverse flex-sm-column-reverse align-items-md-start"
            >
              <div className="">
                <p className="m-0 fw-medium">Our Founder’s Philosophy:</p>
                <p className="m-1">
                  Our founder firmly believes that people must be happy to
                  contribute to the company’s growth.
                </p>
                <p className="m-1">
                  We champion work-life balance and ensure our team enjoys their
                  weekends. By employing world-leading methodologies, we drive
                  sustainable growth while maintaining a healthy, balanced work
                  environment.
                </p>
                <p className="m-1">
                  We invest in our employees’ comfort, providing top-notch
                  equipment and state-of-the-art office infrastructure. Join us
                  and experience a workplace where happiness and success go hand
                  in hand.
                </p>
              </div>

              <div className="mt-2 mb-3 mb-lg-4 mb-sm-3 mb-md-4 badges mx-lg-0">
                <div className="text-start px-3 py-3 py-md-2 ">
                  <p className="card-title text-start text-md-center title mb-0 mt-md-1">
                    Prabu Sekar
                  </p>
                  <p className="paragraph text-start text-md-center des mb-0">
                    CEO / Founder{" "}
                  </p>
                </div>
                <div className="round-div"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section
        className="mt-5 inside-hoffensoft"
        style={{ background: "rgb(245, 237, 254)" }}
      >
        <Container className="lr-padding">
          <Row className="">
            <Col md={{ span: 10, offset: 1 }} sm={12} className="my-5">
              <Heading className="sub-heading">
                Inside <span>Hoffensoft</span>
              </Heading>
              <Content className="paragraph">
                At Hoffensoft, your ideas take flight! Share your innovations,
                and we'll turn them into reality. Thrive in our vibrant,
                collaborative culture, surrounded by the brightest minds.
                Experience where creativity meets opportunity!
              </Content>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="neg-margin">
        <Container className="lr-padding">
          <Row className="slider-row">
            <Col lg={12} md={12} sm={12} className="slider ">
              <div className="slider-container-item">
                <div className="buttons">
                  <button className={`btn prev-btn  p-0 me-2`} onClick={previous}  disabled={currentIndex === 0}>
                    <img src={prevIcon} alt="Previous" className="icon" />
                  </button>
                  <button className="btn nxt-btn p-0" onClick={next}>
                    <img src={nextIcon} alt="Next" className="icon" />
                  </button>
                </div>
                <Slider ref={sliderRef} {...settings}>
                  {managers?.map((manager, idx) => (
                    <div className="" key={idx}>
                      <Card className="">
                        <CardBody className="p-2 p-sm-4 ">
                          <Row className="">
                            <Col lg={4} md={12} sm={12} >
                              <img
                                src={manager.image}
                                className="img-fluid manager-img rounded mx-auto d-block my-auto"
                                alt="manager-img"
                              />
                            </Col>
                            <Col
                              lg={8}
                              md={12}
                              sm={12}
                              className="my-none my-sm-4 px-4 px-lg-5 px-md-4 px-sm-3"
                            >
                              <img
                                src={quotemark}
                                className="img-fluid quote-mark-img"
                                alt="quote-mark-img"
                              />
                              <p 
                              className="mt-4 mt-md-2 mt-sm-5 paragraph text-start"
                              >
                                {manager.content}
                              </p>
                              <div>
                                <p
                                  className="mt-3 mb-0 card-title"
                                  style={{ fontSize: "25px" }}
                                >
                                  {manager.name}
                                </p>
                                <p
                                  className="card-para"
                                  style={{ color: "#4B4B4B" }}
                                >
                                  {manager.position}
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </div>
                  ))}
                </Slider>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container className="lr-padding">
          <Row className="mt-4">
            <Col md={{ span: 10, offset: 1 }} sm={12} className="my-5">
              <Heading className="sub-heading">
                Creating a <span>Knowledge-Driven</span> <br/>
                Environment
              </Heading>
              <Content className="paragraph">
                At Hoffensoft, your voice fuels innovation! Share your
                groundbreaking ideas, and watch them come to life. In our
                dynamic, collaborative culture, your concepts shine, supported
                by the brightest minds in the industry. Experience where
                knowledge drives success!
              </Content>
            </Col>
          </Row>
          <img
            src={
              width > 480
                ? web_career_bottom_banner
                : mobile_career_bottom_banner
            }
            alt="gallery"
            className="img-fluid mb-5 w-100"
          />
        </Container>
      </section>
    </>
  );
};

export default WhyUs;
