import React, {useState} from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import HorizontalScrollCard from "./HorizontalScrollCard";
import Slider from "react-slick";
import "./HorizontalScrollSectionAbout.css"

const HorizontalScrollSection = ({ title, services }) => {

  const [settings] = useState({
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 6000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  });


  return (
    <Container fluid style={{ backgroundColor: "#8447D0" }} >
      <Container className=" scroll-section-container">
        <Row>
          <Col lg={3} className="mx-auto my-auto">
            <h2 className="why-choose scroll-title pe-lg-5 me-lg-5 mx-md-auto" style={{color: "white"}}>{title}</h2>
          </Col>
          <Col lg={9} className="px-0 ">
            {/* <Stack direction="horizontal" className="stack-container"  gap={4}> 
                {
                    services?.map((service)=><HorizontalScrollCard title={service.title} description={service.desc} icon={service.icon}/>)
                }
            </Stack> */}
            <Slider {...settings}> 
                {
                    services?.map((service)=><HorizontalScrollCard title={service.title} description={service.desc} icon={service.icon}/>)
                }
            </Slider>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default HorizontalScrollSection;
