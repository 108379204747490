import React, { useEffect, useState } from "react";
import "./careers.css";
import { Col, Container, Row } from "react-bootstrap";
import careers from "../assests/Careers/career.svg";
import DropdownSelect from "../components/DropdownSelect";
import Input from "../components/Input";
import JobCard from "../components/JobCard";

import NoJob from "../assests/Careers/no_jobs_found.svg";

import search_icon from "../assests/search_icon.svg";
const Careers = () => {
  const dropdownOptions = [
    { label: "Marketing", value: "Marketing" },
    { label: "Sales", value: "Sales" },
    { label: "Human Resources", value: "Human Resources" },
    { label: "Development", value: "Development" },
    { label: "Testing", value: "Testing" },
  ];

  const positions = {
    // "Development": [
    // {
    //   title: "Junior Developer",
    //   description: " ",
    //   location: "Coimbatore, TN",
    //   type: "Full Time",
    // },
    // {
    //   title: "Partner Manager",
    //   description: "Hoffensoft is a catalyst, sparking a revolution in the world of enterprise software and B2B SaaS, ",
    //   location: "Coimbatore, TN",
    //   type: "Full Time",
    // },
    // ],
    // "Testing": [
    //   {
    //     title: "Junior Test Engineer",
    //     description: "Hoffensoft is a catalyst, sparking a revolution in the world of enterprise software and B2B SaaS, ",
    //     location: "Coimbatore, TN",
    //     type: "Full Time",
    //   },
    // ],
    // "Human Resources": [
    //   {
    //     title: "Partner Manager",
    //     description: "Hoffensoft is a catalyst, sparking a revolution in the world of enterprise software and B2B SaaS, ",
    //     location: "Coimbatore, TN",
    //     type: "Full Time",
    //   },
    //   {
    //     title: "Partner Manager",
    //     description: "Hoffensoft is a catalyst, sparking a revolution in the world of enterprise software and B2B SaaS, ",
    //     location: "Coimbatore, TN",
    //     type: "Full Time",
    //   },
    // ],
  };

  const [filteredData, setFilteredData] = useState(positions);
  const [selectedPosition, setSelectedPosition] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    filterPositions();
  }, [selectedPosition, searchQuery]);

  const filterPositions = () => {
    let filtered = positions;

    // Filter by department
    if (selectedPosition) {
      filtered = { [selectedPosition]: positions[selectedPosition] };
    }

    // Filter by search query
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      const filteredByQuery = {};
      for (const dept in filtered) {
        const matchingJobs = filtered[dept].filter((job) =>
          job.title.toLowerCase().includes(query)
        );

        if (matchingJobs.length > 0) {
          filteredByQuery[dept] = matchingJobs;
        }
      }

      filtered = filteredByQuery;
    }

    setFilteredData(filtered);
  };

  const handleDropdownChange = (e) => {
    setSelectedPosition(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div>
      <Container className="career-banner lr-padding">
        <Row className="py-5">
          <Col lg={6} md={12} sm={12} className="my-auto mb-2">
            <h2
              className="heading pb-1 text-lg-start text-md-center text-sm-center"
              style={{ color: "#8447D0" }}
            >
              Join us
            </h2>
            <p className="paragraph banner-description text-lg-start text-md-center text-sm-center mx-sm-auto">
              Accelerate your career with the fastest growing SaaS company, that
              gives you the platform to do amazing things. It's more than just a
              job, it's an opportunity to grow.
            </p>
          </Col>
          <Col lg={6} md={12} sm={12} className="mx-auto">
            <img
              src={careers}
              className="img-fluid mx-auto d-block"
              alt="careers-img"
            />
          </Col>
        </Row>
      </Container>
      <section className="filter-sec">
        <Container className="py-5 lr-padding">
          <Row className="gy-2">
            <Col lg={12} md={12} sm={12} xs={12}>
              <h4 className="sub-heading pb-1 text-start">Open Positions</h4>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <DropdownSelect
                placeholder={"Choose Department"}
                // icon={query_icon}
                options={dropdownOptions}
                onChange={handleDropdownChange}
              />
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Input
                icon={search_icon}
                placeholder={"Search Job Title"}
                onChange={handleSearchChange}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="positions mt-5">
        {Object.keys(filteredData)?.length ? (
          <Container className="lr-padding">
            {Object.keys(filteredData)?.map((position) => (
              <Row className="gy-3 mb-5" key={position}>
                <Col lg={12} sm={12} md={12} xs={12}>
                  <h2 className="sub-heading pb-1 text-start">{position}</h2>
                </Col>
                {filteredData[position]?.length ? (
                  <Col lg={12} sm={12} md={12} xs={12} className="p-0">
                    <Row className="gx-md-5 gy-md-4 listings">
                      {filteredData[position]?.map((item) => (
                        <Col
                          key={item}
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          className="listing"
                        >
                          <JobCard job={item} />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                ) : (
                  <Col lg={12} sm={12} md={12} xs={12} className="p-0">
                    <Container className="no-job d-flex align-items-center justify-content-center">
            <img className="d-block mx-auto" src={NoJob} alt="no data found" />
          </Container>
                  </Col>
                )}
              </Row>
            ))}
          </Container>
        ) : (
          <Container className="no-job d-flex align-items-center justify-content-center">
            <img className="d-block mx-auto" src={NoJob} alt="no data found" />
          </Container>
        )}
      </section>
    </div>
  );
};

export default Careers;
