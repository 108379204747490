import React from "react";
import styled from "styled-components";


const StyledCard = styled.div`
  /* width: 274px; */
  width: 100%;
  border: none;
  border-radius: 25px;
  padding: 20px;
  background-color: #fff;

  margin-right: 40px;
  margin-bottom: 30px;
  height:100%;

  @media screen and (min-width:320px)and (max-width: 425px){
    //width: 260px !important;
    width:100%;
    padding:40px; 
    margin:0px !important;
    /* margin: 0 auto 20px auto; */

  } 
   @media screen and (min-width:426px)and (max-width: 800px){
    margin-right:0px !important;
    padding:30px; 

  }
`;


const CardBody = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

`;

const CardIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
`;

const CardImage = styled.img`
  width: 50px; 
  height: 50px;
  object-fit: cover;
`;

const CardContent = styled.div`
  margin-top: 16px;
`;

const CardTitle = styled.h4`
  width: 100%;
`;

const CardText = styled.p`
  margin-top: 8px;
  width: 100%;
  margin-bottom: 0;

`;

const HorizontalScrollCard_Service = ({icon, title, description}) => {
  return (
    <StyledCard>
      <CardBody>
        <CardIcon>
          <CardImage  src={icon} alt="Cloud Icon" className="img-fluid" />
        </CardIcon>
        <CardContent>
          <CardTitle className="card-title">{title}</CardTitle>
          <CardText  className="card-para">
            {description}
          </CardText>
        </CardContent>
      </CardBody>
    </StyledCard>
  );
};

export default HorizontalScrollCard_Service;


