import React, { useEffect, useRef, useState } from "react";
import Banner from "../components/Banner";
import { Col, Container, Row, Modal } from "react-bootstrap";
import "./Home.css";
import ServiceCard from "../components/ServiceCard";
import CustomeAccordion from "../components/CustomeAccordion";
import Slider from "react-slick";
import { useInView } from "react-intersection-observer";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";

import services from "../assests/services_3d.png";
import services1 from "../assests/services1_3d.png";
import cloud from "../assests/cloud.gif";
import our_dna from "../assests/our_dna.svg";
import whyus from "../assests/why-us-icons/why_us.svg";
import siruthuli_logo from "../assests/siruthuli_logo.png";

import time from "../assests/why-us-icons/Group_679.svg";
import dedicatedsupport from "../assests/why-us-icons/Group_652.svg";
import transparent from "../assests/why-us-icons/Group_653.svg";
import flexibility from "../assests/why-us-icons/Group_654.svg";
import customized from "../assests/why-us-icons/Group_655.svg";
import utilization from "../assests/why-us-icons/Group_680.svg";

import mobile from "../assests/mobile.gif";
import opensource from "../assests/opensource.gif";
// import voice from "../assests/voice.gif";
import manageService from "../assests/Manage_services.gif";
import devops from "../assests/devops.gif";
import analytics from "../assests/analytics.gif";
import Counter from "../components/Counter";

import paytronix from "../assests/px_logo.png";
import truckbee_logo from "../assests/truckbee_logo.png";
import mgm_logo from "../assests/mgm_logo.png";

import prevIcon from "../assests/left_slider.png";
import nextIcon from "../assests/right_slider.png";

import david from "../assests/david.png";
import EJ from "../assests/EJ.png";
import hellena from "../assests/hellena.png";
import avatar from "../assests/Avatar.png";

//marquee images
import Logo_Block from "../assests/client_logos/Logo_Block.png";
import Logo_Block_1 from "../assests/client_logos/Logo_Block-1.png";
import Logo_Block_2 from "../assests/client_logos/Logo_Block-2.png";
import Logo_Block_3 from "../assests/client_logos/Logo_Block-3.png";
import Logo_Block_4 from "../assests/client_logos/Logo_Block-4.png";
import Logo_Block_6 from "../assests/client_logos/Logo_Block-6.png";
import Logo_Block_7 from "../assests/client_logos/Logo_Block-7.png";
import Logo_Block_8 from "../assests/client_logos/Logo_Block-8.png";
import Logo_Block_9 from "../assests/client_logos/Logo_Block-9.png";
import Logo_Block_10 from "../assests/client_logos/Logo_Block-10.png";
import Logo_Block_11 from "../assests/client_logos/Logo_Block-11.png";

import project_truckbee from "../assests/projects_worked/truck_bee.webp";
import project_siruthuli from "../assests/projects_worked/siruthuli.webp";
import project_mgm from "../assests/projects_worked/mgm.webp";
import project_mgm_web from "../assests/projects_worked/mg-web.png";
import project_jkcafe from "../assests/projects_worked/jk_cafe.webp";

const Home = () => {
  const sliderRef = useRef(null);
  const [settings] = useState({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1007,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
  const [show, setShow] = useState(false);
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);

  const handleClose = () => {
    setShow(false);
    setSelectedTestimonial(null);
  };
  const handleShow = () => setShow(true);

  const handleCardClick = (content) => {
    setSelectedTestimonial(content);
    handleShow();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const next = () => {
    sliderRef.current.slickNext();
  };

  const accodianItems = [
    {
      qus: "What is software development?",
      ans: "Software development is the process of designing, creating, testing, and maintaining software applications. It involves several phases, including planning, analysis, design, implementation, testing, and maintenance.",
    },
    {
      qus: "What is web application development?",
      ans: "Web application development involves creating applications that run on a web server and are accessed through a web browser. These applications can range from simple websites to complex web-based systems.",
    },
    {
      qus: "What types of software and web applications do you develop?",
      ans: "We develop a variety of software and web applications, including enterprise applications, mobile applications, e-commerce platforms, content management systems (CMS), customer relationship management (CRM) systems, and more.",
    },
    {
      qus: "How long does it take to develop a software/web application?",
      ans: "The development time varies based on the complexity and scope of the project. A simple application may take a few weeks, while a more complex project can take several months.",
    },
    {
      qus: "How do you ensure the quality of your software/web applications?",
      ans: "We follow industry best practices, use automated testing tools, conduct thorough code reviews, and perform extensive testing to ensure our software/web applications are of high quality.",
    },
    {
      qus: "Do you use Agile methodology?",
      ans: "Yes, we use Agile methodology to manage our development process. This allows us to deliver projects in iterative cycles, providing regular updates and allowing for client feedback throughout the development process.",
    },
    {
      qus: "Can you customize software/web applications to meet specific business needs? ",
      ans: "Absolutely. We specialize in creating custom solutions tailored to the unique requirements of each client. We work closely with our clients to understand their needs and deliver a solution that fits their business.",
    },
    {
      qus: "Do you offer integration with third-party services?",
      ans: "Yes, we can integrate your software/web application with various third-party services, such as payment gateways, social media platforms, CRM systems, and other APIs.",
    },
    {
      qus: "Do you offer post-launch support and maintenance?",
      ans: "Yes, we offer post-launch support and maintenance to ensure your application runs smoothly and stays up-to-date with the latest technologies and security standards.",
    },
    {
      qus: "How do you ensure the security of the applications you develop?",
      ans: "We follow best security practices, conduct regular security audits, and use secure coding techniques to protect applications from vulnerabilities and threats.",
    },
    {
      qus: "How do you handle confidential information and data privacy?",
      ans: "We take data privacy seriously and adhere to strict confidentiality agreements. We implement robust security measures to protect sensitive information and comply with data protection regulations.",
    },
  ];

  const sliderContent = [
    {
      icon: paytronix,
      review:
        "Here at Paytronix we have been a Hoffensoft customer from almost day one. Our company is in the restaurant and convenience store guest engagement business (loyalty, gift cards, marketing campaign technology, online ordering, and more). Hoffensoft has built and still maintains for us the branded (white label) mobile app based on native code for Android and iOS, as well as several custom apps for some of our customers. While that journey is still continuing, we embarked on a new adventure: rebuild our branded app using the latest available technologies, with focus on future maintenance cost and automated testability. We now have a solid foundation again to further build out the functionality of our mobile app. The team we work with is doing a great job and is a pleasure to work with.",
      reviewerName: "Ernst Jan Van De Vegte",
      reviewerPosition: "Director Software Development",
      reviewerImage: EJ,
    },
    {
      icon: siruthuli_logo,
      review:
        "Our company is at the forefront of preserving and rejuvenating aquatic environments, from serene ponds to expansive lakes. Since our initial collaboration with Siruthuli, they have been pivotal in crafting and maintaining a comprehensive React-based dashboard that has transformed our operational management. This dashboard has become an essential tool for our daily activities, providing us with a seamless interface to monitor and manage our water bodies effectively. As we continue to grow and evolve, we’ve decided to upgrade this dashboard with the latest technologies. Our goal is to implement solutions that will lower maintenance costs and enhance automated testability, ensuring the dashboard remains efficient and future-proof. Siruthuli’s expertise and dedication have been crucial in laying a strong foundation for these advancements. Their team’s professionalism and collaborative spirit have made our partnership both productive and enjoyable.",
      reviewerName: "Vanitha Mohan",
      reviewerPosition: "Partner",
      reviewerImage: avatar,
    },
    {
      icon: truckbee_logo,
      review:
        "In the vehicle transport industry, where efficiency and reliability are paramount, our company has relied on TruckBee from the start. They have developed and continue to maintain a robust mobile app using Flutter, designed for both Android and iOS platforms. This app is essential for our operations, enabling us to manage vehicle logistics across various locations seamlessly. Additionally, TruckBee has crafted custom applications tailored to specific needs of our clients, further enhancing our service offerings. Looking ahead, we’re embarking on a major update to our mobile app, incorporating the latest technologies to address future maintenance and automated testing requirements. This revamp aims to build on the solid foundation TruckBee has already provided, allowing us to expand our app’s functionality. The expertise and commitment of TruckBee’s team have been instrumental in our ongoing success, and their collaborative approach has made working with them a true pleasure.",
      reviewerName: "Karthick Chandran",
      reviewerPosition: "Lead",
      reviewerImage: avatar,
    },
    {
      icon: mgm_logo,
      review:
        "Since the inception of our collaboration, MG has been a cornerstone in revolutionizing our office space rental services. Specializing in connecting clients with ideal office spaces, MG has developed and maintains a sophisticated React-based dashboard and a React Native mobile app for both Android and iOS platforms. These tools have been vital in enhancing our operational efficiency and client interaction. As we progress, we have chosen to modernize our mobile app with the latest technological advancements. This upgrade aims to reduce future maintenance costs and improve automated testability, ensuring that our app remains at the cutting edge. The exceptional team at MG has been integral to this process, providing robust solutions and support that have made our partnership highly successful and enjoyable.",
      reviewerName: "Hisam Basheer",
      reviewerPosition: "Manager",
      reviewerImage: avatar,
    },
  ];

  return (
    <div className="home">
      <Banner />
      <section
        className="section-padding"
        id="services"
        style={{
          backgroundColor: "#f5edfe",
        }}
      >
        <Container className="what-we-do lr-padding">
          <div class="static-line"></div>
          <div class="moving-line"></div>
          <div class="static-line-1"></div>
          <div class="moving-line-1"></div>
          <div class="static-line-2"></div>
          <div class="moving-line-2"></div>
          <div class="static-line-3"></div>
          <div class="moving-line-3"></div>
          <img
            src={services}
            alt="Placeholder"
            className="img-fluid rounded service"
            style={{}}
          />
          <Row className="justify-content-center">
            <Col className="align-self-center" lg={8} md={12} sm={12}>
              {/* <h5 className="tag pb-1 text-center" style={{ color: "#8447D0" }}>
                WHAT WE DO
              </h5> 
              */}
              <h2 className="sub-heading text-wrap pb-1 px-5 text-center">
                Unleashing Extraordinary Software Solutions for Your Business
              </h2>
              <p
                className="paragraph text-wrap lh-2 text-center para"
                style={{
                  color: "#454545",
                }}
              >
                From Concept to Execution, We’re Your Trusted Partner in
                Achieving Success.
              </p>
            </Col>
          </Row>
          <Row className="mt-lg-2 mt-md-4 mt-sm-1">
            <Col
              lg={4}
              md={6}
              sm={12}
              xs={12}
              className="service-card-container"
            >
              <ServiceCard
                icon={cloud}
                title={"Cloud"}
                link="service-cloud"
                desc={
                  "Cloud computing gives enterprises the opportunity to set up a virtual office, thus giving them the flexibility to connect to their business anytime."
                }
              />
            </Col>
            <Col
              lg={4}
              md={6}
              sm={12}
              xs={12}
              className="service-card-container mt-lg-5 mt-md-5"
            >
              <ServiceCard
                icon={mobile}
                title={"Mobile"}
                link="service-mobile"
                desc={
                  "Mobile applications  provide a convenient interface to their customers. By delivering mobile application solutions for Android, iOS, and other hybrid platforms."
                }
              />
            </Col>
            <Col
              lg={4}
              md={6}
              sm={12}
              xs={12}
              className="service-card-container mt-md-0"
            >
              <ServiceCard
                icon={opensource}
                title={"Enterprise solutions"}
                link="service-opensource"
                desc={
                  "Open source software help enterprises in developing custom applications across a variety of domains."
                }
              />
            </Col>

            <Col
              lg={4}
              md={6}
              sm={12}
              xs={12}
              className="service-card-container mt-lg-0 mt-md-5"
            >
              <ServiceCard
                icon={analytics}
                title={"Analytics"}
                link="service-analytics"
                desc={
                  "Analytics in business provides enterprises with actionable insights and forecasts that can be used to make better business decisions."
                }
              />
            </Col>
            <Col
              lg={4}
              md={6}
              sm={12}
              xs={12}
              className="service-card-container mt-lg-5 mt-md-0"
            >
              <ServiceCard
                icon={devops}
                title={"DevOps"}
                link="service-devops"
                desc={
                  "DevOps tools and techniques are designed to work on Agile principles, thus providing enterprises with a platform to connect and work on a project."
                }
              />
            </Col>
            <Col
              lg={4}
              md={6}
              sm={12}
              xs={12}
              className="service-card-container mt-lg-0  mt-md-5"
            >
              <ServiceCard
                icon={manageService}
                title={"Managed Service"}
                link="managed-services"
                desc={
                  "Hoffensoft Consult delivers tailored strategies, operational efficiency, and innovative solutions while ensuring smooth transitions through expert change management."
                }
              />
            </Col>
          </Row>
          {/* add thump image */}
          <img className=" services1" src={services1} alt="services-1" />
        </Container>
      </section>
      <section
        className="section-padding"
        style={{ backgroundColor: "#FFFAFA" }}
        ref={ref}
      >
        <Container className="py-1 py-lg-5 py-md-3 lr-padding">
          <Row>
            <Col lg={6} md={12} sm={12} className="align-self-center">
              {/* <h5
                className="tag pb-1 text-lg-start text-sm-center"
                style={{ color: "#8447D0" }}
              >
                OUR DNA
              </h5> 
              Member24
              */}
              <h2 className="sub-heading text-wrap lh-sm pb-1 text-lg-start text-sm-center">
                Explore Our Milestones
              </h2>
              <Row className="mt-lg-3 mt-md-5 mt-sm-3 mt-3">
                <Col lg={6} md={6} sm={12} xs={12} className="count">
                  <p className="heading">
                    <Counter
                      id="count1"
                      start={0}
                      end={100}
                      duration={2000}
                      inView={inView}
                    />
                    +
                  </p>
                  <p className="paragraph text-lg-start">Cloud Projects</p>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="count">
                  <p className="heading">
                    <Counter
                      id="count1"
                      start={0}
                      end={120}
                      duration={2000}
                      inView={inView}
                    />
                    +
                  </p>
                  <p className="paragraph text-lg-start">Engineers On-Board</p>
                </Col>
              </Row>
              <Row className="mt-lg-3 mt-md-4">
                <Col lg={6} md={6} sm={12} xs={12} className="count">
                  <p className="heading">
                    <Counter
                      id="count1"
                      start={0}
                      end={200}
                      duration={2000}
                      inView={inView}
                    />
                    +
                  </p>
                  <p className="paragraph text-lg-start">
                    Enterprise Mobile Apps
                  </p>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="count">
                  <p className="heading">
                    <Counter
                      id="count1"
                      start={0}
                      end={200}
                      duration={2000}
                      inView={inView}
                    />
                    +
                  </p>
                  <p className="paragraph text-lg-start">
                    Customer Referenceability
                  </p>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12} className="">
              <img
                src={our_dna}
                className="img-fluid mx-auto d-block"
                alt="our_dna"
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pt-2 pb-6">
        <Container fluid>
          <div className="marquee">
            <Marquee speed={30}>
              <span style={{ marginRight: "50px" }}>Software Development</span>
            </Marquee>
            <div className="mx-auto absolute">
              {/* <h5 className="tag pb-1 text-center" style={{ color: "#8447D0" }}>
                OUR CLIENTS
              </h5> 
              */}
              <h2 className="sub-heading home-our-clients mt-1 text-center para">
                We are excited to Team Up with Extraordinary Brands Across the
                Globe
              </h2>
            </div>
          </div>
          <Marquee className="mt-5 mt-md-1">
            <img src={Logo_Block} className="marquee-image" alt="logo_block" />
            <img
              src={Logo_Block_1}
              className=" marquee-image"
              alt="logo_block_1"
            />
            <img
              src={Logo_Block_2}
              className=" marquee-image"
              alt="logo_block_2"
            />
            <img
              src={Logo_Block_3}
              className=" marquee-image"
              alt="logo_block_3"
            />
            <img
              src={Logo_Block_4}
              className=" marquee-image"
              alt="logo_block_4"
            />
            <img
              src={Logo_Block_6}
              className=" marquee-image"
              alt="logo_block_6"
            />
            <img
              src={Logo_Block_7}
              className=" marquee-image"
              alt="logo_block_7"
            />
            <img
              src={Logo_Block_8}
              className=" marquee-image"
              alt="logo_block_8"
            />
            <img
              src={Logo_Block_9}
              className=" marquee-image"
              alt="logo_block_9"
            />
            <img
              src={Logo_Block_10}
              className=" marquee-image"
              alt="logo_block_10"
            />
            <img
              src={Logo_Block_11}
              className=" marquee-image"
              alt="logo_block_11"
            />
          </Marquee>
        </Container>
      </section>
      <section
        className="section-padding"
        style={{ backgroundColor: "rgb(245 237 255 / 30%)" }}
      >
        <Container className="lr-padding">
          <Row className="align-items-center gx-md-5">
            <Col
              lg={4}
              md={12}
              sm={12}
              className="why-us-img order-md-2 order-lg-1"
            >
              <img
                src={whyus}
                className="my-auto mx-auto d-block "
                alt="why-us-img"
              />
            </Col>
            <Col lg={8} md={12} sm={12} className="order-md-1 order-lg-1">
              <h2 className="sub-heading lh-sm pt-lg-4 pb-4 text-lg-start">
                Why Partner with Us?
              </h2>

              <Row>
                <Col lg={6} md={6} sm={12} className="why-us-desc why-us-col">
                  <div className="pb-3 why-us-content">
                    <img
                      src={time}
                      className="shadow-sm rounded-circle"
                      alt="time-img"
                    />
                  </div>
                  <h5 className="card-title text-wrap pb-1 text-lg-start">
                    On-Time Delivery, Every Time
                  </h5>
                  <p className="card-para">
                    {/* Experience the assurance of timely project completion with
                    our agile approach. We deliver your mobile app with
                    precision and punctuality, ensuring you stay ahead in the
                    fast-paced market. */}
                    Experience timely project completion and precision delivery
                    of your mobile app with our agile approach, keeping you
                    ahead in a fast-paced market.
                  </p>
                </Col>
                <Col lg={6} md={6} sm={12} className="why-us-desc why-us-col">
                  <div className="pb-3 why-us-content">
                    <img
                      src={dedicatedsupport}
                      className="shadow-sm rounded-circle"
                      alt="dedicate-support-img"
                    />
                  </div>
                  <h5 className="card-title text-wrap lh-sm pb-1 text-lg-start">
                    24/7 Dedicated Support
                  </h5>
                  <p className="card-para">
                    {/* Count on us for unparalleled support that keeps your app
                    running smoothly. Our dedicated team is always ready to
                    assist, ensuring your success never misses a beat in the
                    competitive digital landscape. */}
                    Count on us for unmatched support that keeps your app
                    running smoothly, with our dedicated team always ready to
                    assist in your success.{" "}
                  </p>
                </Col>
                <Col lg={6} md={6} sm={12} className="why-us-desc why-us-col">
                  <div className="pb-3 why-us-content">
                    <img
                      src={transparent}
                      className="shadow-sm rounded-circle"
                      alt="transparent-img"
                    />
                  </div>
                  <h5 className="card-title text-wrap lh-sm pb-1 text-lg-start">
                    Clear and Open Communication
                  </h5>
                  <p className="card-para">
                    {/* Stay in the loop with our crystal-clear, real-time updates.
                    We ensure you're always informed and involved, so your
                    project evolves exactly as envisioned in the dynamic digital
                    arena. */}
                    Stay informed with our real-time updates, ensuring your
                    project evolves as envisioned in the dynamic digital
                    landscape.
                  </p>
                </Col>
                <Col lg={6} md={6} sm={12} className="why-us-desc why-us-col">
                  <div className="pb-3 why-us-content">
                    <img
                      src={flexibility}
                      className="shadow-sm rounded-circle"
                      alt="flexibility-img"
                    />
                  </div>
                  <h5 className="card-title text-wrap lh-sm pb-1 text-lg-start">
                    Agile and Cost-Efficient Solutions
                  </h5>
                  <p className="card-para">
                    {/* Adapt swiftly to market changes with our flexible
                    development approach. We combine agility with
                    cost-effectiveness, providing high-quality results that
                    maximize your investment while meeting your evolving needs. */}
                    Adapt quickly to market changes with our flexible,
                    cost-effective development approach, delivering high-quality
                    results that maximize your investment.
                  </p>
                </Col>
                <Col lg={6} md={6} sm={12} className="why-us-desc why-us-col">
                  <div className="pb-3 why-us-content">
                    <img
                      src={customized}
                      className="shadow-sm rounded-circle"
                      alt="customized-img"
                    />
                  </div>
                  <h5 className="card-title text-wrap lh-sm pb-1 text-lg-start">
                    Tailored Solutions for Your Unique Vision
                  </h5>
                  <p className="card-para">
                    {/* Transform your ideas into reality with bespoke solutions
                    designed to fit your specific needs. We craft personalized
                    strategies that align with your business goals, making sure
                    your app stands out in the market. */}
                    Transform your ideas into reality with tailored solutions
                    that align with your business goals, ensuring your app
                    stands out in the market.
                  </p>
                </Col>
                <Col lg={6} md={6} sm={12} className="why-us-desc why-us-col">
                  <div className="pb-3 why-us-content">
                    <img
                      src={utilization}
                      className="shadow-sm rounded-circle"
                      alt="utilization-img"
                    />
                  </div>
                  <h5 className="card-title text-wrap lh-sm pb-1 text-lg-start">
                    Cutting-Edge Technology Integration
                  </h5>
                  <p className="card-para">
                    {/* Leverage the latest tech trends to propel your app to the
                    forefront of innovation. We harness modern technologies to
                    deliver solutions that are both groundbreaking and
                    market-ready, ensuring you stay ahead of the curve. */}
                    Leverage the latest tech trends to propel your app with
                    groundbreaking, market-ready solutions that keep you ahead
                    of the curve.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-padding">
        <Container className="lr-padding">
          {/* <h5 className="tag pb-1 text-lg-start" style={{ color: "#8447D0" }}>
            OUR PROJECTS
          </h5> */}
          <h2 className="sub-heading text-wrap lh-sm pb-1 mt-3 text-lg-start">
            Our Proven Success Stories
          </h2>
          <p
            style={{ color: "#454545" }}
            className="paragraph text-lg-start home-our-projects-desc"
          >
            Our Successful Software Application Development Services
          </p>
          <Row className="our_projects gx-lg-5 gy-4">
            <Col
              lg={6}
              md={6}
              sm={12}
              className="mb-lg-4 mx-auto d-flex justify-content-center"
            >
              <div className="card">
                <Link to="/project-overview/truck-bee">
                  <img
                    className="img-fluid"
                    src={project_truckbee}
                    alt="truckbee"
                  />
                </Link>
                <div className="d-flex flex-column align-items-start justify-content-center">
                  <p className="card-title m-0 mb-0 mb-lg-1 mb-md-1 mb-sm-0">
                    Truckbee
                  </p>
                  <p className="m-0 ">
                    Premium Car Transport, Where Your Memories Ride in Style.
                  </p>
                </div>
              </div>
            </Col>
            <Col
              lg={6}
              md={6}
              sm={12}
              className="mb-lg-4 mx-auto d-flex justify-content-center"
            >
              <div className="card">
                <Link to="/project-overview/mgw">
                  <img
                    className="img-fluid"
                    src={project_mgm_web}
                    alt="siruthuli"
                  />
                </Link>
                <div className="d-flex flex-column align-items-start justify-content-center">
                  <p className="card-title  m-0 mb-1">MG Web</p>
                  <p className="m-0">Your Go-To Workspace Partner </p>
                </div>
              </div>
            </Col>
            <Col
              lg={6}
              md={6}
              sm={12}
              className="mb-lg-4 mx-auto d-flex justify-content-center"
            >
              <div className="card">
                <Link to="/project-overview/paytronix">
                  <img
                    className="img-fluid"
                    src={project_jkcafe}
                    alt="paytronix"
                  />
                </Link>
                <div className="d-flex flex-column align-items-start justify-content-center">
                  <p className="card-title m-0 mb-1">Paytronix</p>
                  <p className="m-0">
                    Transforming Client Engagement into Unforgettable Guest
                    Experiences.
                  </p>
                </div>
              </div>
            </Col>
            <Col
              lg={6}
              md={6}
              sm={12}
              className="mb-lg-4 mx-auto d-flex justify-content-center"
            >
              <div className="card">
                <Link to="/project-overview/mg">
                  <img className="img-fluid" src={project_mgm} alt="mgm" />
                </Link>
                <div className="d-flex flex-column align-items-start justify-content-center">
                  <p className="card-title m-0 mb-1">MG</p>
                  <p className="m-0">Your Go-To Workspace Partner</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section
        className="section-padding"
        style={{ backgroundColor: "#EBEEFF" }}
      >
        <Container className="lr-padding home-slider">
          <div className="slider-container">
            <div className="header-buttons-container">
              <div className="header">
                <h2 className="sub-heading text-lg-start">
                  Hear it from our clients
                </h2>
              </div>
              <div className="buttons">
                <button className="btn  p-0 me-2" onClick={previous}>
                  <img src={prevIcon} alt="Previous" />
                </button>
                <button className="btn p-0" onClick={next}>
                  <img src={nextIcon} alt="Next" />
                </button>
              </div>
            </div>
            <Slider ref={sliderRef} {...settings}>
              {sliderContent?.map((content, i) => (
                <div key={i}>
                  <div
                    className="card"
                    onClick={() => handleCardClick(content)}
                  >
                    <div className="img-container">
                      <img
                        src={content.icon}
                        style={{ height: "auto", width: "auto" }}
                        alt="siruthuli_logo"
                        className="img-fluid"
                      />
                    </div>
                    <p className="content mb-0 mt-2 card-para text-start">
                      {content.review}
                    </p>
                    <div className="photo-container d-flex align-items-center mt-4 ">
                      <img
                        src={content.reviewerImage}
                        alt="avatar"
                        className="img-fluid client-img"
                      />
                      <div className="name d-flex flex-column ps-3">
                        <span className="paragraph reviewer-name text-start">
                          {content.reviewerName}
                        </span>
                        <span
                          className="paragraph text-start reviewer-position"
                          style={{ color: "#475569" }}
                        >
                          {content.reviewerPosition}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </Container>
      </section>
      <section className="section-padding">
        <Container className="lr-padding">
          <h2 className="sub-heading text-wrap lh-sm pb-1 text-lg-center lr-padding">
            Frequently Asked Questions
          </h2>
          <p className="paragraph text-center mb-5">
            FAQs On Software & Web Application Development Services
          </p>
          <CustomeAccordion items={accodianItems} />
        </Container>
      </section>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <div className="modal_card card" closeButton>
          <div className="img-container">
            <img
              src={selectedTestimonial?.icon}
              style={{ height: "auto", width: "auto" }}
              alt="siruthuli_logo"
              className="img-fluid"
            />
          </div>
          <p className="content mb-0 mt-2 card-para text-start">
            {selectedTestimonial?.review}
          </p>
          <div className="photo-container d-flex align-items-center mt-4 ">
            <img
              src={selectedTestimonial?.reviewerImage}
              alt="avatar"
              className="img-fluid client-img"
            />
            <div className="name d-flex flex-column ps-3">
              <span className="paragraph reviewer-name text-start">
                {selectedTestimonial?.reviewerName}
              </span>
              <span
                className="paragraph text-start reviewer-position"
                style={{ color: "#475569" }}
              >
                {selectedTestimonial?.reviewerPosition}
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Home;
