import React, { useEffect } from "react";
import ManageServiceBanner from "../../components/ManageServiceBanner";
import cloudBanner from "../../assests/Service_Cloud/cloud.mp4";
// import chatIcon from "../../assests/Downarrow.svg"
import { Button, Col, Container, Row } from "react-bootstrap";
import "../../pages/service/manageServices.css";

import businessVissionIcon from "../../assests/ManageServices/vision.gif";
import empowerData from "../../assests/ManageServices/empower.gif";
import buildResilence from "../../assests/ManageServices/Build-resilience-1.gif";
import accelerateInnovation from "../../assests/ManageServices/innovation.gif";

import consult from "../../assests/ManageServices/consult.svg";

import effectiveChange from "../../assests/ManageServices/Consult-delivers-value/Effective-Change-Management.svg";
import effectiveOptmisation from "../../assests/ManageServices/Consult-delivers-value/Efficiency-and-Optimization.svg";
import innovativeSoln from "../../assests/ManageServices/Consult-delivers-value/Innovative-Solutions.svg";
import strategicInsight from "../../assests/ManageServices/Consult-delivers-value/Strategic-Insights-and-Guidance.svg";
import chatIcon from "../../assests/ManageServices/message-square.svg";

import partnerIcon from "../../assests/ManageServices/CMIT-solutions-logo.png";
import RightArrow from "../../assests/right_slider.png";

import { useNavigate } from "react-router-dom";

const bannerTitle = "Your purpose, our strategy ";
const bannerdescription =
  "Cloud is about how you do computing, not where you do computing.";

const data = [
  {
    icon: businessVissionIcon,
    title: "Enable Business Vision",
  },
  {
    icon: empowerData,
    title: "Empower data-led decisions",
  },
  {
    icon: buildResilence,
    title: "resilience",
  },
  {
    icon: accelerateInnovation,
    title: "Accelerate innovation",
  },
];

// const items = [
//     {
//         image: effectiveChange,
//         title: "Strategic Insights and Guidance",
//         desc: "Hoffensoft Consult offers tailored strategies and market insights that align with your specific goals, helping you navigate complex challenges and seize growth opportunities effectively."
//     },
//     {
//         image: effectiveOptmisation,
//         title: "Efficiency and Optimization",
//         desc: "By streamlining processes and improving operational efficiency, Hoffensoft Consult enhances productivity and reduces costs, ensuring your organization runs smoothly and effectively."
//     },
//     {
//         image: innovativeSoln,
//         title: "Innovative Solutions",
//         desc: "The firm provides innovative, customized solutions to address your unique challenges, driving competitive advantage and ensuring that your strategies are both practical and forward-thinking."
//     },
//     {
//         image: strategicInsight,
//         title: "Effective Change Management",
//         desc: "Hoffensoft Consult supports smooth transitions during change initiatives with expert planning, communication, and training, helping your organization adapt and thrive in evolving environments."
//     }
// ]

const ManageServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(`/${path}`);
  };

  return (
    <div>
      <ManageServiceBanner
        video={cloudBanner}
        title={bannerTitle}
        description={bannerdescription}
        icon={chatIcon}
      />
      <Container className="section-padding">
        {/* <h6 className='tag p-2'>CONSULTING</h6> */}
        <h3 className="sub-heading p-2">Strategy for action</h3>
        <p className="paragraph para-style">
          Today's business landscape calls for new enterprise strategies,
          technological innovation, and a holistic approach to the changing
          expectations of customers and employees. To thrive amidst change, you
          need a transformative business strategy centered on purpose.
        </p>
      </Container>
      <Container
        fluid
        style={{ backgroundColor: "rgb(245, 237, 254)" }}
        className="pt-5 pb-5"
      >
        <Container>
          <h6 className="tag our-approach">OUR APPROACH</h6>
          <Row>
            {data.map((item, index) => (
              <Col lg={3} md={3} sm={12} key={index} className="">
                <img
                  className="mx-auto d-block manage-approaches"
                  src={item.icon}
                  alt="our-approach-icons"
                />
                <p className="faq-question our-approach-para">{item.title}</p>
              </Col>
            ))}
          </Row>
        </Container>
      </Container>
      <Container>
        <Row>
          <Col lg={7} md={12} sm={12} className="pt-5 align-self-center">
            <h6 className="card-title pb-4">Our Partners</h6>
            <h2 className="sub-heading pb-3" style={{ textAlign: "left" }}>
              CMIT Solutions
            </h2>
            <p>
              In today's fast-paced business environment, agility, mobility, and
              efficiency are more crucial than ever.
            </p>
            <p>
              You need an IT service provider that not only meets your current
              needs but also propels your business to new heights.
            </p>
            <p>
              Explore the comprehensive range of services we offer, from ongoing
              IT support and maintenance to advanced cybersecurity solutions.
            </p>
            <p>
              Partner with us to experience the full potential of
              technology—without the headaches
            </p>
            <a href="https://cmitsolutions.com/silverspring-md-1076" style={{color: "#8447d0"}}>
              Explore more
            </a>
          </Col>
          <Col lg={5} md={12} sm={12}>
            <img className="mx-auto w-100" src={consult} alt="consulting" />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12} className="pt-5">
            <h6 className="card-title pb-4">Our Customers</h6>
            <Row>
              <Col lg={6} md={12} sm={12} className="customer-card text-center">
                <div className="customer-card-container">
                  <img className="partner-icon" src={partnerIcon} />
                  <div className="d-flex align-items-center justify-content-center">
                    <span className="partner-name">CMIT sliver spring</span>
                    {/* <img src={RightArrow} /> */}
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12} sm={12} className="customer-card text-center">
                <div className="customer-card-container">
                  <img className="partner-icon" src={partnerIcon} />
                  <div className="d-flex align-items-center justify-content-center">
                    <span className="partner-name">CMIT nikhil</span>
                    {/* <img src={RightArrow} /> */}
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12} sm={12} className="customer-card text-center">
                <div className="customer-card-container">
                  <img className="partner-icon" src={partnerIcon} />
                  <div className="d-flex align-items-center justify-content-center">
                    <span className="partner-name">CMIT dyal</span>
                    {/* <img src={RightArrow} /> */}
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12} sm={12} className="customer-card text-center">
                <div className="customer-card-container">
                <p class="tandem">TANDEM</p>
                  <div className="d-flex align-items-center justify-content-center">
                    <span className="partner-name">tandem cpa</span>
                    {/* <img src={RightArrow} /> */}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          {/* <Col lg={4} md={12} sm={12}>
                        <img className='mx-auto w-100' src={consult} alt='consulting' />
                    </Col> */}
        </Row>
      </Container>
      {/* <Container>
                <p className='heading heading-alignment'>How Hoffensoft <span style={{ color: '#8447D0' }}>Consult delivers value</span></p>
                <Row className='gx-0 gx-lg-5 gx-md-4 gx-sm-0 gy-5'>
                    {items.map((data, index) => (
                        <Col lg={6} md={6} sm={12} key={index} className=''>
                            <img src={data.image} className='w-100' alt={data?.title}/>
                            <h6 className='card-title mt-5' >{data?.title}</h6>
                            <div className='mt-4 mb-3 underline'></div>
                            <p>{data?.desc}</p>
                        </Col>
                    ))}
                </Row>
            </Container>
            <Container style={{backgroundColor:'#FAF6FF', borderRadius:'26px'}} className='my-5 d-flex flex-column align-items-center transform-container'>
                <h5 className='sub-heading pb-1'>Transformation starts here</h5>
                <p className='pb-2'>Looking to turn purpose into action?</p>
                <Button variant="outline" className=" px-4 py-3 chat-btn rounded-pill w-5 btn-text" onClick={() => handleNavigation("support")}>
                    Talk to our Experts
                    <span className='ps-2'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                </Button>
            </Container> */}
    </div>
  );
};

export default ManageServices;
