import React from "react";
import { Form } from "react-bootstrap";
import "./dropdownSelect.css";
import InputGroup from "react-bootstrap/InputGroup";

const DropdownSelect = ({options, placeholder, onChange, icon, borderRadius}) => {
  return (
    <InputGroup className={`mb-3 ${borderRadius ? "" : "border-radius"}`}>
      {icon &&  <InputGroup.Text id="basic-addon1">
        <img src={icon} className="img-fluid icon" />
      </InputGroup.Text>}
      <Form.Select
        placeholder={placeholder}
        aria-label={placeholder}
        aria-describedby="basic-addon1"
        onChange={onChange}
      >
        <option value="">{placeholder}</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </InputGroup>
  );
};

export default DropdownSelect;
