import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import { Form } from 'react-bootstrap';

import "./searchBox.css"
const SearchBox = ({ icon, placeholder, borderRadius,  onClick}) => {
  const [input, setInput] = React.useState("")
  return (
    <InputGroup className={`search-box ${borderRadius ? "" : "border-radius"}`}>
      <Form.Control
        placeholder={placeholder}
        aria-label={placeholder}
        aria-describedby="basic-addon1"
        className='px-4'
        onChange={(e)=>setInput(e.target.value)}
      />
      <InputGroup.Text id="basic-addon2" onClick={()=>onClick(input)}><img src={icon ? icon : null} className='img-fluid icon' /></InputGroup.Text>
    </InputGroup>
  )
}
export default SearchBox;