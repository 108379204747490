import React, { useEffect } from "react";
import HeroBanner from "../../components/HeroBanner";
import AnalyticsBannerBg from "../../assests/services_analytics/analytics_video.mp4";
import DataDisplay from "../../components/DataDisplay";
import analyticsImg from "../../assests/services_analytics/analytics.gif";
import { Stack } from "react-bootstrap";

import devProcess from "../../assests/services_analytics/process_web.svg";
import backImg from "../../assests/back_img.png";
import icon1 from "../../assests/services_analytics/why_1.svg";
import icon2 from "../../assests/services_analytics/why_2.svg";
import icon3 from "../../assests/services_analytics/why_3.svg";
import icon4 from "../../assests/services_analytics/why_4.svg";
import icon5 from "../../assests/services_analytics/why_5.svg";
import useWindowDimensions from "../../hooks/useWindowDimension.js";
import "./analytics.css";
import "./service.css";
import "../../components/horizontalScrollSectionService.css";
import WhatDoWeServe from "../../components/WhatDoWeServe";
import devProcessMobile from "../../assests/services_analytics/process_mobile.svg";

import HorizontalScrollSectionService from "../../components/HorizontalScrollSectionService.js";

const Analytics = () => {
  const { height, width } = useWindowDimensions();

  const services = [
    {
      title: "Advanced Technology",
      icon: icon1,
      description:
        "Maximizing the power of advanced tools like Power BI, Tableau, and QlikView, we ensure our clients extract maximum value from their data.",
    },
    {
      title: "Data-Driven Decision Making",
      icon: icon2,
      description:
        "Hoffensoft’s BI and analytics services transform raw data into meaningful insights, empowering organizations to make data-driven decisions with confidence. Turn your data into your greatest asset!",
    },
    {
      title: "User-Friendly Dashboards",
      icon: icon3,
      description:
        "Hoffensoft’s BI tools are designed for ease of use, empowering users at all levels to access and understand critical data effortlessly. Get insights at your fingertips!",
    },
    {
      title: "Integration Capabilities",
      icon: icon4,
      description:
        "Our BI and analytics solutions effortlessly integrate with your existing systems and data sources, delivering powerful insights without a hitch.",
    },
    {
      title: "Ongoing Support and Training",
      icon: icon5,
      description:
        "Hoffensoft offers continuous support and training to ensure clients fully maximize the value of their BI and analytics solutions. We're with you every step of the way, turning potential into performance!",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const bannerTitle = "Analytics";
  const bannerDisc = "Data speaks volumes if you're ready to listen!";

  const heading = "Supercharge Your Business with Intelligence and Analytics";
  const description =
    "Business intelligence and analytics empower enterprises and startups to grow and boost operational efficiency at every level. By leveraging data as a corporate asset, organizations can optimize and automate business processes using the insights gained. With Hoffensoft’s cutting-edge business analytics tools, you’ll have the solutions you need to make smarter, better business decisions.";

  const data = {
    "Business Intelligence and Analytics": {
      title: "Intelligence and Analytics",
      description: {
        content:
          "Our business intelligence solutions give you a competitive edge with accurate and enhanced reporting. Through dedicated analysis of your organizational data, we:",
        list: [
          "Empower you to make informed business decisions",
          "Improve the quality of your datasets",
          "Provide fact-based, actionable business insights",
        ],
      },
    },
    "Big Data": {
      title: "Big Data",
      description: {
        content:
          "Our big data solutions harness the power of large datasets to deliver actionable insights that help you save costs, reduce time, and understand market conditions. Our offerings include:",
        list: [
          "Data ingestion from multiple sources",
          "ETL (Extract, Transform, Load) tools for:",
          [
            "Data migration between systems and applications",
            "Implementation of analytical techniques in legacy systems",
          ],
          "Analyzing massive volumes of data using Hadoop",
          "Business-specific modeling to derive meaningful insights",
        ],
      },
    },
    " Machine Learning": {
      title: "Machine Learning",
      description: {
        content:
          "Our machine learning solutions are tailored to your company data and customer behaviors, providing valuable enhancements such as:",
        list: [
          "Building predictive models based on current and historical data",
          "Offering predefined machine learning models",
          "Forecasting business trends with predictive analytics",
          "Charting your upcoming business growth",
        ],
      },
    },
  };

  return (
    <>
      <HeroBanner
        video={AnalyticsBannerBg}
        title={bannerTitle}
        description={bannerDisc}
        button={true}
      />
      <section className="">
        <DataDisplay
          title={heading}
          description={description}
          // image={analyticsImg}
          image={"/assets/svg/analytics.svg"}

        />
      </section>
      <HorizontalScrollSectionService
        services={services}
        whyChooseTitle="Why choose Hoffensoft for Business Intelligence Services"
      />
      <div className="py-5">
        <Stack>
          <h2 className="sub-heading sub-heading-styling text-center">
            Our Analytics Process
          </h2>
          <p
            className="paragraph mt-2 mt-lg-4 mt-md-4 mt-sm-2 text-center analytics-process-desc"
            style={{ color: "#4B4B4B" }}
          >
            We guarantee a transparent and seamless experience for our clients
            throughout the entire development journey. Enjoy clarity and ease
            every step of the way!
          </p>
        </Stack>
        <img
          src={width > 730 ? devProcess : devProcessMobile}
          className="img-fluid dev-process-img"
          alt={width > 700 ? "web-dev-process" : "mobile-dev-process"}
        />
      </div>

      <WhatDoWeServe
        title={"What Do We Serve ?"}
        description={`At Hoffensoft, we dive deep into your organization’s data, performing expert statistical analysis to empower you with data-driven insights. Make informed business decisions with confidence and clarity!`}
        data={data}
        bgImg={backImg}
        position="top center"
      />
    </>
  );
};

export default Analytics;
