import React from "react";
import styled from "styled-components";



const StyledCard = styled.div`
  min-width: 300px;
  min-height: 330px;
  height: 100%;
  border: none;
  border-radius: 20px;
  padding: 35px 35px 15px 35px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #F8F9F9;
  margin: 0px 20px;

  @media screen and (min-width: 320px) and (max-width: 425px){
    min-width: 260px;
    min-height: 230px;
    padding: 20px 20px 15px 20px;
    margin-bottom: 20px;
  }
  @media screen and (min-width: 426px) and (max-width: 480px){
    min-width: 260px;
    min-height: 230px;
    padding: 20px 20px 15px 20px;
  }

  @media screen and (min-width: 480px) and (max-width: 800px){
    margin-bottom: 20px;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const CardIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
`;

const CardImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;

  @media screen and (min-width: 320px) and (max-width: 425px){
    width: 50px;
    height: 50px;
  }
`;

const CardContent = styled.div`
  margin-top: 16px;
  @media screen and (min-width: 320px) and (max-width: 425px){
    margin-top: 10px;
  }
`;

const CardTitle = styled.h4`
  margin-top: 8px;
  width: 100%;
  @media screen and (min-width: 320px) and (max-width: 425px){
    margin-top: 0px;
  }

`;

const CardText = styled.p`

  margin-top: 8px;

  width: 100%;
  margin-bottom: 0px;

`;

const HorizontalScrollCard = ({icon, title, description}) => {
  return (
    <StyledCard>
      <CardBody>
        <CardIcon>
          <CardImage src={icon} alt="Cloud Icon" />
        </CardIcon>
        <CardContent>
          <CardTitle className="card-title">{title}</CardTitle>
          <CardText className="card-para">
            {description}
          </CardText>
        </CardContent>
      </CardBody>
    </StyledCard>
  );
};

export default HorizontalScrollCard;
