import React from "react";
import styled from "styled-components";
import moment from "moment";

const StyledCard = styled.div`
  min-width: 300px;
  min-height: 330px;
  height: 100%;
  border: none;
  border-radius: 20px;
  width: 374px;
  @media (max-width:576px) {
    width: 100% !important;
    height: auto;

  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CardIcon = styled.div`
  width: 100%;
`;

const CardImage = styled.img`
  border-radius: 22.6px;
  cursor: pointer;
  width: 374px;
  height: 204px;
  object-fit: fill;

  @media (max-width:576px) {
    width: 100% !important;
    height: auto;
  }
`;


const CardContent = styled.div`
  margin-top: 25px;
`;

const CardTitle = styled.h4`
  text-align: left;
  cursor: pointer;
  text-transform: capitalize;
`;

const CardText = styled.p`
  color: #898989;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; 
  overflow: hidden;

`;
const CardTime = styled.p`
  color: #8447d0;
  margin: 0;
  padding-bottom: 15px;

`;

const BlogCard = ({ blog, onClick }) => {
  return (
    <StyledCard onClick={onClick}>
      <CardBody>
        <CardIcon>
          <CardImage className="img-fluid" src={blog?.image} alt="Cloud Icon" />
        </CardIcon>
        <CardContent>
          <CardTime className="paragraph text-start">
            {blog?.date ? moment(blog?.date).format("MMMM D, YYYY") : ""}
          </CardTime>
          <CardTitle className="card-title">{blog?.title}</CardTitle>
          <CardText className="card-para mb-5 text-start" dangerouslySetInnerHTML={{ __html: blog?.description }}></CardText>
        </CardContent>
      </CardBody>
    </StyledCard>
  );
};

export default BlogCard;