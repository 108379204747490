import { useEffect, useState } from 'react';


const Counter = ({ id, start, end, duration, inView }) => {
  const [count, setCount] = useState(start);

  useEffect(() => {
    let current = start;
    const range = end - start;
    const increment = end > start ? 1 : -1;
    const step = Math.abs(Math.floor(duration / range));
    const timer = setInterval(() => {
      current += increment;
      setCount(current);
      if (current === end) {
        clearInterval(timer);
      }
    }, step);

    return () => clearInterval(timer);
  }, [start, end, duration, inView]);

  return count;
};

export default Counter
