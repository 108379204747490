import React, { useEffect } from "react";
import HeroBanner from "../../components/HeroBanner";
import DevopsBanner from "../../assests/Services_Devops/cloud.mp4";
import DataDisplay from "../../components/DataDisplay";
import DevopsImg from "../../assests/Services_Devops/dev_ops.gif";
import { Stack } from "react-bootstrap";

import partnerIcon from "../../assests/partnerIcon2.png";
import icon1 from "../../assests/Services_Devops/why_1.svg";
import icon2 from "../../assests/Services_Devops/why_2.svg";
import icon3 from "../../assests/Services_Devops/why_3.svg";
import icon4 from "../../assests/Services_Devops/why_4.svg";
import icon5 from "../../assests/Services_Devops/why_5.svg";
import devProcess from "../../assests/Services_Devops/procees_web.svg";
import backImg from "../../assests/back_img.png";
import devProcessMobile from "../../assests/Services_Devops/process_mobile.svg";
import WhatDoWeServe from "../../components/WhatDoWeServe";
import "../../components/horizontalScrollSectionService.css";
import useWindowDimensions from "../../hooks/useWindowDimension";
import "./devops.css";

import HorizontalScrolSectionService from "../../components/HorizontalScrollSectionService";

const Devops = () => {
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const bannerTitle = "Dev Ops";
  const bannerDisc = `Our industry is surfing a tidal wave of innovation, driven by the groundbreaking power of the cloud. Embrace the future with cutting-edge solutions that are reshaping the landscape!
`;

  const heading = "Boost Your Productivity with DevOps";
  const description =
    "DevOps supercharges your organization’s productivity with a suite of tools and techniques that foster seamless team collaboration. Built on Agile principles, DevOps offers a dynamic platform for your business to connect and collaborate without delays. At Hoffensoft, we recognize that key processes, success attributes, operational frameworks, and tools are essential to your enterprise’s progress. Our DevOps solutions harness these elements to deliver application and infrastructure migration that drives your organization’s success.";

  const data = {
    "Amazon Web Services (AWS)": {
      title: "Amazon Web Services (AWS)",
      description: {
        content:
          "We provide end-to-end services to help organizations identify and implement the best AWS data solutions for their needs, including:",
        list: [
          "Version control and compliance support",
          "Continuous integration and cloud automation",
          "AWS DevOps and security",
          "Log and configuration management",
          "Cloud security framework",
          "Monitoring and alerting",
          "Service discovery and support coverage",
          "Container orchestration",
        ],
      },
    },
    "Google Cloud Platform": {
      title: "Google Cloud Platform ",
      description:
        "Hoffensoft specializes in developing customized GCP solutions that empower enterprises to create and maintain high-performance applications powered by Google Cloud. Unlock your potential with our tailored expertise!",
    },
    "IBM Cloud": {
      title: "IBM Cloud",
      description:
        "Our IBM Cloud solutions automate organizational workflows by enabling the rapid development, testing, and deployment of enterprise applications on a unified cloud architecture. Streamline your operations and drive innovation with our expertise!",
    },
    "Microsoft Azure": {
      title: "Microsoft Azure",
      description:
        "Our Azure solutions are designed to build, deploy, and manage enterprise-specific applications while effectively reducing infrastructure costs. Optimize your operations and enhance efficiency with our expert services!",
    },
  };

  const services = [
    {
      icon: icon1,
      title: "Comprehensive Expertise",
      description:
        "Our expertise covers the full DevOps lifecycle, from continuous integration and delivery (CI/CD) to automated testing and deployment. Experience end-to-end mastery that drives your success!",
    },
    {
      icon: icon2,
      title: "Automation Excellence",
      description:
        "Our automation solutions turbocharge development cycles, cut down errors, and elevate product quality. Experience the next level of efficiency and excellence!",
    },
    {
      icon: icon3,
      title: "Seamless Collaboration",
      description:
        "Our integrated approach sparks synergy across teams, accelerating delivery, boosting quality, and aligning perfectly with your business objectives. Experience the power of collaboration like never before!",
    },
    {
      icon: icon4,
      title: "Enhanced Security and Compliance",
      description:
        "In DevOps, security and compliance are non-negotiable. Hoffensoft puts these top of mind in all our solutions, ensuring your operations are both secure and compliant. Safeguard your success with our top-tier protection!",
    },
    {
      icon: icon5,
      title: "Reliable Support",
      description:
        "Our dedicated support team is on call to tackle any issues swiftly, minimizing downtime and maximizing your productivity. Count on us for seamless operations and uninterrupted success!",
    },
  ];

  return (
    <>
      <HeroBanner
        video={DevopsBanner}
        title={bannerTitle}
        description={bannerDisc}
        partnerIcon={partnerIcon}
        button={true}
      />
      <section className="">
        <DataDisplay
          title={heading}
          description={description}
          // image={DevopsImg}
          image={"/assets/svg/devops.svg"}
        />
      </section>
      <HorizontalScrolSectionService
        services={services}
        whyChooseTitle="Why choose Hoffensoft for Devops Services"
      />
      <div className="py-5">
        <Stack>
          <h2 className="sub-heading sub-heading-styling text-center">
            Experience the Future with Our DevOps Process!
          </h2>
          <p
            className="paragraph para mt-2 mt-lg-4 mt-md-4 mt-sm-2 text-center devops-process-desc"
            style={{ color: "#4B4B4B" }}
          >
            Enjoy a transparent and seamless development journey with our DevOps
            excellence!
          </p>
        </Stack>
        <img
          src={width > 730 ? devProcess : devProcessMobile}
          className="img-fluid dev-process-img"
          alt={width > 700 ? "web-dev-process" : "mobile-dev-process"}
        />
      </div>

      <WhatDoWeServe
        title="What Do We Serve ?"
        description="Through configuration and infrastructure management, and deployment automation, we deliver our expertise on the following tools:"
        bgImg={backImg}
        data={data}
        position="top center"
      />
    </>
  );
};

export default Devops;
