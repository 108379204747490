import React from "react";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import PropTypes from "prop-types";


const StyledButton = styled(Button)`
  border-radius: ${({ shape }) => (shape === "round" ? "50px" : "5px")};
  padding: ${({ shape }) =>
    shape === "round"
      ? "12px 24px"
      : "8px 16px"}; 
  border: ${({ outlined, variant, border }) =>
    outlined
      ? border
        ? `1px solid ${border}`
        : `1px solid ${variant}`
      : "none"};
  background: ${({ outlined, variant }) =>
    outlined ? "transparent" : variant};
  color: ${({ color }) => color};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: background 0.3s, color 0.3s, border 0.3s;

  @media screen and (min-width: 320px) and (max-width: 480px){
    padding: 6px 12px;
    font-size: 14px;
  }

  @media screen and (min-width: 481px) and (max-width: 800px){
    padding: 10px 10px;
    font-size: 16px;
  }

  .icon {
    margin-right: ${({ icon }) => (icon ? "8px" : "0")};
    height: 20px; 
    width: 20px; 
  }

  .arrow {
    width: 9px;
    height: 14px;
    margin-left: 5px;
  }

  &:hover {
    background: ${({ outlined, variant }) =>
      outlined ? variant : "#4b0082"}; 
    color: ${({ outlined }) => (outlined ? "#fff" : "#fff")};
  }

  &:focus {
    background: ${({ outlined, variant }) =>
      outlined ? variant : "#4b0082"}; 
    color: ${({ outlined }) => (outlined ? "#fff" : "#fff")};
  }

  svg path{
    fill: ${({color}) => color};
  }

  &:hover svg path {
    fill: #fff;
  }

  &:focus svg path {
    fill: #fff;
  }
`;


const CustomButton = ({
  variant,
  value,
  onClick,
  shape,
  outlined,
  icon,
  arrow,
  border,
  color,
  margin,
  className
}) => {
  return (
    <StyledButton
      variant={variant} 
      onClick={onClick}
      shape={shape}
      outlined={outlined ? 1 : 0}
      icon={icon ? 1 : 0}
      border={border}
      color={color}
      margin={margin}
      className = {className}
    >
      {icon && <img className="icon" src={icon} alt="icon" />} {value}{" "}
      {arrow && (
        <span className="ms-1">
          <svg
            width="12"
            height="12"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 61.245L41.2244 36L15 10.755L23.0735 3L57.4286 36L23.0735 69L15 61.245Z"
              fill="#8447D0"
            />
          </svg>
        </span>
      )}
    </StyledButton>
  );
};

CustomButton.propTypes = {
  variant: PropTypes.string,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  shape: PropTypes.oneOf(["square", "round"]),
  outlined: PropTypes.bool,
  icon: PropTypes.string, // URL of the PNG icon
  border: PropTypes.string,
  className: PropTypes.string
};

export default CustomButton;