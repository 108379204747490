import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "./accordion.css";

import Slider from "react-slick";

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 3000,
  cssEase: "linear",
};

const CustomeAccordion = ({ btncss, itemcss, items, number, onClick }) => {
  return (
    <Accordion defaultActiveKey={number ? 0 : ""}>
      {items?.map((item, idx) => (
        <Accordion.Item
          eventKey={idx}
          key={idx}
          onClick={() => onClick && onClick(idx)}
          className={number ? "with-number" : ""}
        >
          {number && <span className="number inter-bold">{`0${idx + 1}`}</span>}
          <Accordion.Header className={`faq-question ${btncss} `}>
            {item.qus}
          </Accordion.Header>
          <Accordion.Body className={`${itemcss}`}>
            {item.ans}
            {items[idx].image ? Array.isArray(items[idx].image) ? (
              <div className="slider-container">
                <Slider {...settings}>
                  {items[idx]?.image?.map((item, index) => (

                    <div key={index}>
                      <img
                        src={item}
                        className="rounded img-fluid mx-auto d-md-none d-lg-none accordian-img"
                        alt="siruthuli-items"
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            ) : (
              <img
                src={items[idx]?.image}
                className="rounded img-fluid mx-auto d-lg-none d-md-none d-sm-block accordian-img"
                alt="siruthuli-items"
              />
            ) : null}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default CustomeAccordion;
