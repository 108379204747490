import React from "react";
import { Col, Row } from "react-bootstrap";
import "./jobCard.css"

const JobCard = ({ job }) => {
  return (
    <Row className="card-container">
      <Col lg={8} md={7} sm={7} xs={7} className="px-lg-4 py-lg-4">
        <h2 className="card-title pb-2 text-start" style={{color: "black"}}>{job?.title}</h2>
        <p className="card-para job-desc  ">{job?.description}</p>
      </Col>
      <Col lg={4} md={5} sm={5} xs={5} className="location-container py-lg-3 px-lg-3">
        <div className="location d-flex flex-column justify-content-center">

         <h6 className="faq-question pb-2 text-center" style={{color: "black"}}>{job?.location}</h6>
        <p className="faq-ans type text-center">{job?.type}</p>
        </div>
      </Col>
    </Row>
  );
};

export default JobCard;
