//

import React from "react";
import styled from "styled-components";
import { Container, Button, Row, Col } from "react-bootstrap";
import "./heroBanner.css";
import { useNavigate } from "react-router-dom";

const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 700px;

  @media screen and (min-width: 320px) and (max-width: 480px) {
    padding: 0% 5%;
    height: calc(480px - 5vh) !important;
  }

  @media (max-width: 800px) {
    width: 100%;
    height: calc(550px - 5vh);
  }
`;

const VideoBackground = styled.video.attrs({
  playsInline: true, // Add this attribute to ensure inline play in Safari
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(132, 71, 208, 0.8); /* Adjust the opacity as needed */
`;

const BannerContent = styled(Container)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  color: #fff;
  z-index: 1;

  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */

  @media (max-width: 800px) {
    top: 100px;
    transform: translate(-50%, 50px);
  }

  @media (min-width: 320px) and (max-width: 480px) {
    transform: translate(-50%, -10px);
    padding: 0% 6%;
  }
`;

const BannerHeading = styled.h1`
  margin-bottom: 16px;

  @media (max-width: 768px) {
  }
  @media (min-width: 426px) and (max-width: 576px) {
    text-align: center;
  }

  @media (min-width: 320px) and (max-width: 425px) {
    text-align: center;
    margin-bottom: 0px;
  }
`;

const BannerDescription = styled.p`
  margin-bottom: 32px;
  max-width: 1000px;

  @media (max-width: 768px) {
  }
  @media (max-width: 576px) {
  }
`;

const HeroBanner = ({ video, title, description, icon }) => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(`/${path}`);
  };
  return (
    <BannerContainer>
      <VideoBackground autoPlay muted loop playsInline>
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </VideoBackground>
      <Overlay />
      <BannerContent>
        <Row>
          <Col lg={6} md={6} sm={12} className="d-flex flex-column align-items-center justify-content-center">
            <BannerHeading className="heading text-md-left text-sm-center text-xs-center">
              {title}
            </BannerHeading>
            <BannerDescription className="paragraph">
              {description}
            </BannerDescription>
            <Button
              variant="outline"
              className=" px-4 py-3 hero-btn rounded-pill w-5"
              onClick={() => handleNavigation("support")}
            >
              Let's Talk
              <span className="ps-2">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </Button>
          </Col>
          <Col lg={6} md={6} sm={12} className="d-flex flex-column align-items-center justify-content-center mt-lg-0 mt-md-0 mt-sm-4 mt-3">
            <BannerHeading className="heading text-md-left text-sm-center text-xs-center">
              Accounting &
            </BannerHeading>
            <BannerHeading className="heading text-md-left text-sm-center text-xs-center">
              Book Keeping
            </BannerHeading>

            {/* <BannerDescription className="paragraph">
              {description}
            </BannerDescription>
            <Button
              variant="outline"
              className=" px-4 py-3 hero-btn rounded-pill w-5"
              onClick={() => handleNavigation("support")}
            >
              Let's Talk
              <span className="ps-2">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </Button> */}
          </Col>
        </Row>
        {/* <BannerHeading className="heading text-md-left text-sm-center text-xs-center">
                    {title}
                </BannerHeading>
                <BannerDescription className="paragraph">
                    {description}
                </BannerDescription>
                <Button variant="outline" className=" px-4 py-3 hero-btn rounded-pill w-5" onClick={() => handleNavigation("support")}>
                    Let's Talk
                    <span className="ps-2">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </span>

                </Button> */}

        {/* {button && 
        <Button variant="outline" className=" hero-btn rounded-pill px-4 py-3" onClick={()=>handleNavigation("support")}>
          Start a Project
        </Button>
        }
        {partnerIcon && (
          <img className="partner-style" src={partnerIcon} alt="Partner Icon" />
        )} */}
      </BannerContent>
    </BannerContainer>
  );
};

export default HeroBanner;
