import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import banner_video from "../assests/banner_video.mp4";
import CustomButton from "./CustomButton";
import video_asset1 from "../assests/video_asset1.png";
import video_asset2 from "../assests/video_asset2.png";
import { useNavigate } from "react-router-dom";

import thumbnail from "../assests/banner_thumbnail.webp";

const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 640px;

  @media screen and (min-width: 320px) and (max-width: 480px) {
    height: 400px;
  }

  @media screen and (min-width: 481px) and (max-width: 800px) {
    height: 540px;
  }

  .video-asset-1,
  .video-asset-2 {
    position: absolute;
    z-index: 0;
  }

  .video-asset-1 {
    top: 10%;
    left: 70px;
    height: 25px;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  .video-asset-2 {
    top: 70%;
    left: 470px;
    height: 65px;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const VideoBackground = styled.video.attrs({
  playsInline: true, // Add this attribute to ensure inline play in Safari
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
`;

const BannerContent = styled(Container)`
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translateY(-50%);
  text-align: left;
  color: #fff;
  z-index: 1;

  .banner-heading-container {
    overflow: hidden;
    position: relative;
    height: 120px;
    margin-bottom: 20px;
  }
  .animated-text {
    position: absolute;
    bottom: -130px;
    animation: slide-up 1s forwards;
  }

  @keyframes slide-up {
    0% {
      bottom: -130px; /* Start below the view */
      opacity: 0; /* Start transparent */
    }
    100% {
      bottom: -15px; /* End at the normal position */
      opacity: 1; /* End fully visible */
    }
  }

  @media screen and (min-width: 321px) and (max-width: 480px) {
    left: 0px;
    text-align: center;

    .banner-heading-container {
      height: 120px;
    }

    @keyframes slide-up {
      0% {
        bottom: -90px; /* Start below the view */
        opacity: 0; /* Start transparent */
      }
      100% {
        bottom: -20px; /* End at the normal position */
        opacity: 1; /* End fully visible */
      }
    }
  }

  @media screen and (max-width: 320px) {
    left: 0px;
    text-align: center;

    .banner-heading-container {
      height: 130px;
    }

    .animated-text{
      font-size: 25px !important;
    }

    @keyframes slide-up {
      0% {
        bottom: -90px; /* Start below the view */
        opacity: 0; /* Start transparent */
      }
      100% {
        bottom: -0px; /* End at the normal position */
        opacity: 1; /* End fully visible */
      }
    }
  }

  @media screen and (min-width: 481px) and (max-width: 1007px) {
    left: 35px;
    text-align: center;

    

    @keyframes slide-up {
      0% {
        bottom: -90px; /* Start below the view */
        opacity: 0; /* Start transparent */
      }
      100% {
        bottom: -30px; /* End at the normal position */
        opacity: 1; /* End fully visible */
      }
    }
  }

  /* .banner-heading-container {
    height: 90px;
  } */
`;

const BannerHeading = styled.h1`
  margin-bottom: 20px;

  span {
    color: #8447d0;
  }

  @media screen and (max-width: 320px) {
    line-height: 33px;
    margin-bottom: 0px;
    text-align: center;
  }
  @media screen and (min-width: 321px) and (max-width: 480px) {
    line-height: 33px;
    margin-bottom: 35px;
    text-align: center;
  }

  @media screen and (min-width: 481px) and (max-width: 1007px) {
    line-height: 50px !important;
    margin-bottom: 25px;
    text-align: center;
  }
`;

const BannerDescription = styled.p`
  margin-bottom: 32px;
  @media screen and (max-width: 360px) {
  }
`;

const Banner = () => {
  const navigate = useNavigate();

  return (
    <BannerContainer>
      <VideoBackground autoPlay muted loop playsInline poster={thumbnail}>
        <source src={banner_video} type="video/mp4" />
        Your browser does not support the video tag.
      </VideoBackground>
      <Overlay />
      <img className="video-asset-1" src={video_asset1} alt="video-asset-1" />
      <img className="video-asset-2" src={video_asset2} alt="video-asset-2" />
      <BannerContent>
        <Row className="lr-padding">
          <Col lg={6} md={12} sm={12}>
            <div className="banner-heading-container">
              <BannerHeading className="heading animated-text text-lg-start text-md-center text-sm-center">
                Empowering Global Brands to Lead in the
                <span className> Digital Era</span>
              </BannerHeading>
            </div>
            <BannerDescription className="paragraph text-lg-start text-md-center text-sm-center">
              Experience excellence with Hoffensoft’s top-rated software
              development services. Our dedicated developers immerse themselves
              in your business needs, delivering solutions that drive success.
            </BannerDescription>
            <CustomButton
              variant="#8447d0" // Purple color
              value="Learn More"
              onClick={() => {
                navigate("/about-us");
              }}
              shape="round"
              outlined={false} // Filled button
              arrow={true}
              color="#fff"
            />
          </Col>
        </Row>
      </BannerContent>
    </BannerContainer>
  );
};

export default Banner;
