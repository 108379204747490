import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import CustomButton from "./CustomButton";
import Logo from "../assests/hoffensoft_logo.svg";
import "./Header.css";
import { Col, Row } from "react-bootstrap";
import right_arrow_purple from "../assests/right-arrow-purple.png";

import cloud from "../assests/cloud.gif";
import mobile from "../assests/mobile.gif";
import opensource from "../assests/opensource.gif";
import analytics from "../assests/analytics.gif";
import devops from "../assests/devops.gif";
// import voice from "../assests/voice.gif";
import manageService from "../assests/Manage_services.gif";
import helpingHandGif from "../assests/care.gif"
import careersGif from "../assests/success.gif"

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isShrunk, setIsShrunk] = useState(false);
  const [showAboutContent, setShowAboutContent] = useState(false);
  const [showServiceContent, setShowServiceContent] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleAboutClick = () => {
    setShowServiceContent(false);
    setShowAboutContent((prevState) => !prevState);
  };

  const handleServiceClick = () => {
    setShowAboutContent(false);
    setShowServiceContent((prevState) => !prevState);
  };

  const handleNavigation = (path) => {
    setShowServiceContent(false);
    setShowAboutContent(false);

    if (`/${path}` === location.pathname) {
      window.scrollTo(0, 0);
    }
    setExpanded(false);
    navigate(`/${path}`);
  };

  const handleScroll = (e) => {
    if (window.scrollY >= 80) {
      setIsShrunk(true);
    } else {
      setIsShrunk(false);
    }
  };

  console.log("about arrow", showAboutContent);

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      handleScroll(e);
    });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Navbar
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
      expand="lg"
      className={`header-navbar ${isShrunk ? "shrink" : ""}`}
    >
      <Container className="px-2">
        <Navbar.Brand
          className="navbar-brand-custom"
          onClick={() => handleNavigation("")}
        >
          <img
          style={{cursor:'pointer'}}
            src={Logo}
            alt="Hoffensoft Logo"
            className={`logo ${isShrunk ? "shrink-img" : ""}`}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0" navbarScroll>
            <Nav.Link
              className="inter-medium nav-link-custom tabs-fw-sm"
              onClick={() => handleNavigation("")}
            >
              Home
            </Nav.Link>
            <Nav.Link
              className="inter-medium nav-link-custom about-link"
              onClick={handleAboutClick}
            >
              About{" "}
              <span className="ps-2">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 72 72"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={showAboutContent ? "rotate" : ""}
                >
                  <path
                    d="M10.9694 14.7857L36.2144 41.0101L61.4594 14.7857L69.2144 22.8592L36.2144 57.2143L3.21436 22.8592L10.9694 14.7857Z"
                    fill="#0F172A"
                  />
                </svg>
              </span>
            </Nav.Link>
            <Nav.Link
              className="inter-medium nav-link-custom service-link"
              // href="#services"
              onClick={handleServiceClick}
            >
              Services{" "}
              <span className="ps-2">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 72 72"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={showServiceContent ? "rotate" : ""}
                >
                  <path
                    d="M10.9694 14.7857L36.2144 41.0101L61.4594 14.7857L69.2144 22.8592L36.2144 57.2143L3.21436 22.8592L10.9694 14.7857Z"
                    fill="#0F172A"
                  />
                </svg>
              </span>
            </Nav.Link>

            <NavDropdown
              title={
                <span style={{ color: "black" }}>
                  About
                  <span className="ms-2">
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 72 72"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={showAboutContent ? "rotate" : ""}
                    >
                      <path
                        d="M10.9694 14.7857L36.2144 41.0101L61.4594 14.7857L69.2144 22.8592L36.2144 57.2143L3.21436 22.8592L10.9694 14.7857Z"
                        fill="#0F172A"
                      />
                    </svg>
                  </span>
                </span>
              }
              className="inter-regular nav-link-custom tabs-fw-sm"
            >
              <NavDropdown.Item
                className="dropdown-item-custom ms-3"
                onClick={() => {
                  handleAboutClick();
                  handleNavigation("about-us");
                }}
              >
                Explore
              </NavDropdown.Item>
              <NavDropdown.Item
                className="inter-medium dropdown-item-custom ms-3"
                onClick={() => {
                  handleAboutClick();
                  handleNavigation("why-hoffensoft");
                }}
              >
                <img
                  className="about-gif"
                  src={careersGif}
                  alt="careers-gif"
                />
                Careers
              </NavDropdown.Item>
              <NavDropdown.Item
                className="inter-medium dropdown-item-custom ms-3"
                onClick={() => {
                  handleAboutClick();
                  handleNavigation("helping-hand");
                }}
              >
                <img
                  className="about-gif me-2"
                  src={helpingHandGif}
                  alt="helping-hand-gif"
                />
                Helping Hands
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              title={
                <span style={{ color: "black" }}>
                  Services
                  <span className="ms-2">
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 72 72"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={showServiceContent ? "rotate" : ""}
                    >
                      <path
                        d="M10.9694 14.7857L36.2144 41.0101L61.4594 14.7857L69.2144 22.8592L36.2144 57.2143L3.21436 22.8592L10.9694 14.7857Z"
                        fill="#0F172A"
                      />
                    </svg>
                  </span>
                </span>
              }
              className="inter-regular nav-link-custom tabs-fw-sm"
            >
              <NavDropdown.Item
                className="dropdown-item-custom ms-3"
                onClick={() => {
                  handleServiceClick()
                  handleNavigation("service-cloud")
                }}
              >
                <img
                  className="services-gif me-1"
                  src={cloud}
                  alt="cloud-gif"
                />
                Cloud
              </NavDropdown.Item>
              <NavDropdown.Item
                className="inter-medium dropdown-item-custom ms-3"
                onClick={() => {
                  handleServiceClick()
                  handleNavigation("service-mobile")
                }}
              >
                <img
                  className="services-gif me-1"
                  src={mobile}
                  alt="mobile-gif"
                />
                Mobile
              </NavDropdown.Item>
              <NavDropdown.Item
                className="inter-medium dropdown-item-custom ms-3"
                onClick={() => {
                  handleServiceClick()
                  handleNavigation("service-opensource")
                }}
              >
                <img
                  className="services-gif me-1"
                  src={opensource}
                  alt="OpenSource-gif"
                />
                OpenSource
              </NavDropdown.Item>
              <NavDropdown.Item
                className="inter-medium dropdown-item-custom ms-3"
                onClick={() => {
                  handleServiceClick()
                  handleNavigation("service-analytics")
                }}
              >
                <img
                  className="services-gif me-1"
                  src={analytics}
                  alt="OpenSource-gif"
                />
                Anaytics
              </NavDropdown.Item>
              <NavDropdown.Item
                className="inter-medium dropdown-item-custom ms-3"
                onClick={() => {
                  handleServiceClick()
                  handleNavigation("service-devops")
                }}
              >
                <img
                  className="services-gif me-1"
                  src={devops}
                  alt="devops-gif"
                />
                DevOps
              </NavDropdown.Item>
              <NavDropdown.Item
                className="inter-medium dropdown-item-custom ms-3"
                onClick={() => {
                  handleServiceClick()
                  handleNavigation("managed-services")
                }}
              >
                <img
                  className="services-gif me-1"
                  src={manageService}
                  alt="manage_services-gif"
                />
                Managed Services
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link
              className="inter-medium nav-link-custom tabs-fw-sm"
              onClick={() => handleNavigation("blogs")}
            >
              Blogs
            </Nav.Link>
          </Nav>
          <Nav>
            <div className="mb-4 mb-lg-0 mb-md-4 mb-sm-4 mt-0 mt-lg-0 mt-md-3 mt-sm-3">
              <CustomButton
                variant="#8447d0"
                value="Contact"
                onClick={() => handleNavigation("support")}
                outlined={true}
                className="mb-lg-0 mb-md-0 mb-sm-5 mb-xs-5 w-100 w-lg-0 w-md-100 w-sm-100"
              />
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>

      {showAboutContent && (
        <div className="about-dropdown-content">
          <Container>
            <Row className=" row-container">
              <Col
                className=" border-right col"
                lg={4}
                onClick={() => handleNavigation("about-us")}
                style={{cursor: "pointer"}}
              >
                <h4 className="roboto-medium pb-1" style={{ color: "black" }}>
                  We are rooted in relentless curiosity.
                </h4>
                <p
                  className="explore roboto-regular" 
                >
                  Explore More{" "}
                  <img src={right_arrow_purple} alt="right-arrow-purple" />
                </p>
              </Col>
              <Col
                className="border-right col ps-3"
                lg={4}
                onClick={() => handleNavigation("why-hoffensoft")}
                style={{cursor: "pointer"}}
              >
                <div className="d-flex align-items-center">
                  <img
                    className="about-gif"
                    src={careersGif}
                    alt="careersGif"
                  />
                  <div className="ps-1">
                    <Link style={{ textDecoration: "none" }}>
                      <h4 className="roboto-medium pb-1" style={{ color: "black"}} >
                        Career
                      </h4>
                      <p className="explain">
                        Empower Your Career with Us!
                      </p>
                    </Link>
                  </div>
                </div>


              </Col>
              <Col
                className="col ps-3"
                lg={4}
                onClick={() => handleNavigation("helping-hand")}
                style={{cursor: "pointer"}}
              >
                <div className="d-flex align-items-center ">
                  <img
                    className="about-gif"
                    src={helpingHandGif}
                    alt="helping-hand-gif"
                  />
                  <div className="ps-3">
                    <Link style={{ textDecoration: "none" }}>
                      <h4 className="roboto-medium pb-1" style={{ color: "black" }}>
                        Helping Hands
                      </h4>
                      <p className="explain">
                        Empowering Communities Through Action!
                      </p>
                    </Link>
                  </div>
                </div>

              </Col>
            </Row>
          </Container>
        </div>
      )}
      {showServiceContent && (
        <div className="service-dropdown-content">
          <Container>
            <Row className="row-container">
              {/* <Col
                className="col align-self-center "
                lg={3}
                onClick={() => handleNavigation("about-us")}
              >
                <h4 className="roboto-medium pb-1" style={{ color: "black" }}>
                  We are rooted in relentless curiosity.
                </h4>
                <p
                  className="explore roboto-regular"
                >
                  Explore More{" "}
                  <img src={right_arrow_purple} alt="right-arrow-purple" />
                </p>
              </Col> */}
              <Col className="col" lg={12}>
                <Row className="">
                  <Col
                    lg={4}
                    md={4}
                    className="line py-4"
                    onClick={() => handleNavigation("service-cloud")}
                  >
                    <div className="d-flex align-items-center">
                      <img className="col-icon" src={cloud} alt="cloud-icon" />
                      <div className="ps-1">
                        <Link style={{ textDecoration: "none" }}>
                          <h4
                            className="roboto-medium pb-1"
                            style={{ color: "black" }}
                          >
                            Cloud
                          </h4>
                        </Link>
                        <p className="m-0 inter-regular">Cloud Computing</p>
                      </div>
                    </div>
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    className="line py-4"
                    onClick={() => handleNavigation("service-mobile")}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        className="col-icon"
                        src={mobile}
                        alt="mobile-icon"
                      />
                      <div className="ps-1">
                        <Link style={{ textDecoration: "none" }}>
                          <h4
                            className="roboto-medium pb-1"
                            style={{ color: "black" }}
                          >
                            Mobile
                          </h4>
                        </Link>
                        <p className="m-0 inter-regular">
                          Mobile Appplications
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    className="line py-4"
                    onClick={() => handleNavigation("service-opensource")}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        className="col-icon"
                        src={opensource}
                        alt="open-source-icon"
                      />
                      <div className="ps-1">
                        <Link style={{ textDecoration: "none" }}>
                          <h4
                            className="roboto-medium pb-1"
                            style={{ color: "black" }}
                          >
                            Enterprise solutions
                          </h4>
                        </Link>
                        <p className="m-0 inter-regular">
                          Open Source softwares
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg={4}
                    md={4}
                    className="line bottom py-4"
                    onClick={() => handleNavigation("service-analytics")}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        className="col-icon"
                        src={analytics}
                        alt="analytics-icon"
                      />
                      <div className="ps-1">
                        <Link style={{ textDecoration: "none" }}>
                          <h4
                            className="roboto-medium pb-1"
                            style={{ color: "black" }}
                          >
                            Analytics
                          </h4>
                        </Link>
                        <p className="m-0 inter-regular">
                          Analytics in business
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    className="line bottom py-4"
                    onClick={() => handleNavigation("service-devops")}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        className="col-icon"
                        src={devops}
                        alt="devops-icon"
                      />
                      <div className="ps-1">
                        <Link style={{ textDecoration: "none" }}>
                          <h4
                            className="roboto-medium pb-1"
                            style={{ color: "black" }}
                          >
                            DevOps
                          </h4>
                        </Link>
                        <p className="m-0 inter-regular">
                          DevOps tools and techniques
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    className="line bottom py-4"
                    onClick={() => handleNavigation("managed-services")}
                  >
                    <div className="d-flex align-items-center">
                      <img className="col-icon" src={manageService} alt="manage_services-icon" />
                      <div className="ps-1">
                        <Link style={{ textDecoration: "none" }}>
                          <h4
                            className="roboto-medium pb-1"
                            style={{ color: "black" }}
                          >
                            Managed Services
                          </h4>
                        </Link>
                        <p className="m-0 inter-regular">
                          Strategic Solutions for Seamless Success
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </Navbar>
  );
};

export default Header;
