import React from "react";
import styled from "styled-components";
import CustomButton from "./CustomButton"; 
import cloud from "../assests/cloud.gif"; 

import { useNavigate } from "react-router-dom";

const StyledCard = styled.div`
  width: auto;
  border: none;
  border-radius: 46px;
  margin: 0 20px 0;
  background-color: #fff;
  min-height: 402px;

  @media screen and (min-width: 320px) and (max-width: 480px){
     min-height: auto;
    margin: 0px 5px;
    padding-bottom: 10px;
  }

  @media screen and (min-width: 481px) and (max-width: 800px){
    min-height: 380px;
    margin: 0px 5px;
    padding-bottom: 10px;
  }

  @media screen and (min-width: 801px) and (max-width: 1024px){
    min-height: 405px;
    margin: 0px 5px;
    padding-bottom: 10px;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 40px 30px;
  @media screen and (min-width: 320px) and (max-width: 480px){
    padding: 10px 25px 10px;
  }

  @media screen and (min-width: 801px) and (max-width: 1024px){
    padding: 20px 20px;
  }
  
`;

const CardIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

const CardImage = styled.img`
  width: 108px;
  height: 108px;
  object-fit: cover;
  @media screen and (min-width: 320px) and (max-width: 800px){
    margin-top: 10px;
    width: 75px;
    height: 75px;
  }
  
`;

const CardContent = styled.div`
  margin-top: 0px;
`;

const CardTitle = styled.h4`
@media screen and (min-width: 320px) and (max-width: 425px){
  text-align: start !important;
}
`;

const CardText = styled.p`
@media screen and (min-width: 320px) and (max-width: 425px){
  text-align: start !important;
}
`;

const ServiceCard = ({title, desc, icon, link}) => {
  const navigate = useNavigate();
  return (
    <StyledCard>
      <CardBody>
        <CardIcon>
          <CardImage src={icon} alt="Cloud Icon" />
        </CardIcon>
        <CardContent>
          <CardTitle className="card-title pb-3">{title}</CardTitle>
          <CardText className="card-para">
            {desc}
          </CardText>
          <CustomButton
            variant="#8447d0"
            color="#8447d0"
            value="Learn More"
            onClick={() => {navigate(`/${link}`)}}
            shape="round"
            outlined={true}
            arrow={true}
            border="#000000"
          />
        </CardContent>
      </CardBody>
    </StyledCard>
  );
};

export default ServiceCard;