import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import hoffensoft_logo from "../assests/hoffensoft_logo.svg";
import email from "../assests/Email.png"

import facebook from "../assests/facebook.svg"
import linkedin from "../assests/linked-in.svg"
import { useNavigate, Link , useLocation} from "react-router-dom";

const FooterContainer = styled.footer`
  background-color: #ebeeff;
  padding-top: 2rem;

  .footer-img{
    width: 200px;
  }

  @media screen and (min-width: 426px) and (max-width: 567px){
.footer-bottom{
  padding: 10px 0px;
}
.copy-rights .col-top{
  order: 3;
  padding: 0 !important;
}
.copy-rights .col-mid{
  order: 2;
  justify-content: center;
}

.col-mid p{
  text-align: center !important;

}
.copy-rights .col-bottom{
  order: 1;
  justify-content: center;
}

.col-top p{
  text-align: center;
}
}

  @media screen and (min-width: 320px) and (max-width: 425px){
    .footer-bottom{
      padding: 10px 0px;
    }
    .copy-rights .col-top{
      order: 3;
      padding: 0 !important;
    }
    .copy-rights .col-mid{
      order: 2;
      justify-content: center;
    }
    
    .col-mid p{
      text-align: center !important;

    }
    .copy-rights .col-bottom{
      order: 1;
      justify-content: center;
    }

    .col-top p{
      text-align: center;
    }
  }

  .footicon{
    width: 25px;
    height: 25px;
  }
`;

const FooterHeading = styled.h5`
  margin-bottom: 1rem;
  color: #333;
  text-decoration: underline;
  text-underline-offset: 10px;
  text-decoration-color: #8447D0;
`;

const FooterLink = styled.a`
  text-decoration: none;
  color: black;

  @media screen and (max-width: 800px){
    &:hover {
    color: black !important;
  }
  }

  &:hover {
    color: #8447d0;
  }
  &:active{
    color: black;
  }
`;

const FooterList = styled.ul`
  padding: 0;
  list-style-type: none;
  li{
    padding: 7px 0;
    cursor: pointer;
  }
`;

const FooterText = styled.p`
  color: #000000; 
  width: auto;
  line-height: 33.92px;
  padding-right: 180px;

  a{
    color: black;
    text-decoration: none;
  }

  @media screen and (max-width: 800px){
    padding-right: 0px;
  }
`;



const Footer = () => {


  const navigate = useNavigate()

  const location = useLocation()

  const handleNavigate = (path) => {
    
    if(`/${path}` === location.pathname){
      window.scrollTo(0,0)
    }
    navigate(`/${path}`)
  }


  return (
    <FooterContainer>
      <Container className="lr-padding">
        <Row>
          <Col lg={6} md={12} sm={12} xs={12} className="mb-4">
            <img src={hoffensoft_logo} alt="Hoffensoft Logo" className="mb-2 footer-img" />{" "}
            <FooterText className="">
              Hoffensoft is a cloud services firm that specializes in delivering end-to-end enterprise solutions. We work closely with our clients to provide custom applications that empower them to maximize their resources.
            </FooterText>
            <FooterText>
              <img className="pe-2" src={email} alt="email" /> <a href="mailto:contact@hoffensoft.com">contact@hoffensoft.com</a>
            </FooterText>
          </Col>
          <Col lg={2} md={4} sm={6} xs={6}  className="mb-4 ps-md-5">
            <FooterHeading className="roboto-bold">Quick Links</FooterHeading>
            <FooterList className="">
              <li>
                <FooterLink onClick={() => handleNavigate("")}>Home</FooterLink>
              </li>
              <li>
                <FooterLink onClick={() => handleNavigate("blogs")}>
                  Blogs
                </FooterLink>
              </li>
              <li>
                <FooterLink onClick={() => handleNavigate("support")}>
                  Contact
                </FooterLink>
              </li>
            </FooterList>
          </Col>
          <Col lg={2} md={4} sm={6} xs={6} className="mb-4 ps-md-5">
            <FooterHeading className="roboto-bold">About</FooterHeading>
            <FooterList className="">
              <li>
                <FooterLink onClick={() => handleNavigate("careers")}>
                  Careers
                </FooterLink>
              </li>
              <li>
                <FooterLink onClick={() => handleNavigate("helping-hand")}>
                  Helping Hand
                </FooterLink>
              </li>
              <li>
                <FooterLink onClick={() => handleNavigate("why-hoffensoft")}>
                  Why Hoffensoft
                </FooterLink>
              </li>
            </FooterList>
          </Col>
          <Col lg={2} md={4} sm={6} xs={6} className="mb-4 ps-md-5">
            <FooterHeading>Services</FooterHeading>
            <FooterList className="">
              <li>
                <FooterLink onClick={() => handleNavigate("service-cloud")}>
                  Cloud
                </FooterLink>
              </li>
              <li>
                <FooterLink onClick={() => handleNavigate("service-analytics")}>
                  Analytics
                </FooterLink>
              </li>
              <li>
                <FooterLink onClick={() => handleNavigate("managed-services")}>
                  Managed Services
                </FooterLink>
              </li>
              <li>
                <FooterLink onClick={() => handleNavigate("service-mobile")}>
                  Mobile
                </FooterLink>
              </li>
              <li>
                <FooterLink
                  onClick={() => handleNavigate("service-opensource")}
                >
                  Open Source
                </FooterLink>
              </li>
              <li>
                <FooterLink onClick={() => handleNavigate("service-devops")}>
                  Dev Ops
                </FooterLink>
              </li>
            </FooterList>
          </Col>
        </Row>
      </Container>
      <Container fluid className="footer-bottom" style={{ backgroundColor: "#8447D01A" }}>
        <Container>
          <Row className="py-2 copy-rights">
            <Col lg={9} md={7} sm={12} xs={12} className="text-md-start text-sm-center order-md-1 order-sm-3 col-top">
              <p className="mb-0 border">
                &copy; 2024 Hoffensoft. All rights reserved.
              </p>
            </Col>
            <Col
              lg={2} md={3} sm={12} xs={12}
              className="align-items-center d-inline-flex justify-content-md-end justify-content-sm-center order-md-2 order-sm-2 col-mid"
            >
              <p className="mb-0">Connect with us</p>
            </Col>{" "}
            <Col lg={1} md={2} sm={12} xs={12} className="align-items-center d-inline-flex justify-content-sm-center order-md-3 order-sm-1 col-bottom">
              <Link target="_blank" to="https://www.facebook.com/hoffensoft/">
                <img className="footicon img-fluid mx-2" src={facebook} alt="fb-icon" />
              </Link>
              <Link
                target="_blank"
                to="https://in.linkedin.com/company/hoffensoft"
              >
                <img className="footicon img-fluid mx-2" src={linkedin} alt="linkedin" />
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>
    </FooterContainer>
  );
};

export default Footer;