import React, { useEffect } from "react";
import HeroBanner from "../../components/HeroBanner";
import cloudBanner from "../../assests/Service_Cloud/cloud.mp4";
import DataDisplay from "../../components/DataDisplay";
import { Stack } from "react-bootstrap";
import icon1 from "../../assests/Service_Cloud/why_1.svg";
import icon2 from "../../assests/Service_Cloud/why_2.svg";
import icon3 from "../../assests/Service_Cloud/why_3.svg";
import icon4 from "../../assests/Service_Cloud/why_4.svg";
import icon5 from "../../assests/Service_Cloud/why_5.svg";
import devProcess from "../../assests/Service_Cloud/process_web.svg";
import backImg from "../../assests/back_img.png";
import WhatDoWeServe from "../../components/WhatDoWeServe";
import "../../components/horizontalScrollSectionService.css";
import "./service.css";
import "./cloud.css";

import useWindowDimensions from "../../hooks/useWindowDimension";
import devProcessMobile from "../../assests/Service_Cloud/process_mobile.svg";
import HorizontalScrollSectionService from "../../components/HorizontalScrollSectionService";

const Cloud = () => {
  const { height, width } = useWindowDimensions();

  const services = [
    {
      title: "Expertise",
      icon: icon1,
      description:
        "Our team of cloud computing experts brings extensive experience to the table, delivering tailored advice and solutions that meet your unique needs. Trust us to provide top-notch expertise and innovative solutions!",
    },
    {
      title: "Comprehensive Services",
      icon: icon2,
      description:
        "We offer end-to-end solutions that cover everything from planning and implementation to ongoing management and support. Experience seamless service that drives success at every stage!",
    },
    {
      title: "User-Centric Design",
      icon: icon3,
      description:
        "We believe technology should elevate your experience. That's why our user-centric design approach focuses on creating intuitive, scalable solutions that grow with you. Experience innovation tailored to your needs!",
    },
    {
      title: "Customized Solutions",
      icon: icon4,
      description:
        "We get it—every business is unique. That’s why we offer tailor-made solutions that perfectly align with your specific goals and challenges. Discover how our personalized approach can drive your success!",
    },
    {
      title: "Long Term Relationship",
      icon: icon5,
      description:
        "At Hoffensoft, we see technology as a powerful tool to reach new heights. That's why we focus on developing scalable solutions designed to grow with you. Let's build a long-term relationship that drives your success to the next level!",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const bannerTitle = "Cloud Services";
  const bannerDisc =
    "Cloud computing is all about how you do your computing, not just where you do it. Embrace the flexibility and innovation of cloud technology to transform your operations!";

  const heading = "Cloud: Driving Transformation Forward ";
  const description =
    "Cloud technology is a game-changer, yet many enterprises face hurdles in expanding and demonstrating its value. Hoffensoft Cloud Services offer a 360-degree approach, tackling challenges head-on and propelling your transformation journey. Unlock the full potential of the cloud and align IT with business outcomes!";

  const data = {
    "Sales Force": {
      title: "Sales Force",
      description: {
        content:
          "Salesforce automates your business tasks and internal operations, enhancing your customer interactions. Our Salesforce solutions specialize in:",
        list: [
          "End-to-end custom solutions for the cloud platform",
          "Implementation of other cloud services with Salesforce",
          "Migration of legacy systems to Salesforce",
          "Effortless cloud adoption",
        ],
      },
    },
    "Service Now": {
      title: "Service Now",
      description: {
        content:
          "ServiceNow is an enterprise cloud platform that defines and structures the organizational workflow into a streamlined delivery of products and services. We provide ServiceNow solutions like:",
        list: [
          "Implementation and integration with IoT, Salesforce, and JIRA",
          "Mobile computing and social collaboration",
          "Optimized solutions with flexible working models",
          "Adaptability for social collaboration",
        ],
      },
    },
    JIRA: {
      title: "JIRA",
      description: {
        content:
          "JIRA is a ticketing management tool that enhances teamwork with its consolidated user management interface. With over eight years of experience in JIRA implementation, Hoffensoft specializes in:",
        list: [
          "Integration and automation with ServiceNow, Salesforce, and Alexa",
          "Bug tracking and issue resolution",
          "Project management with iterative scrum boards",
          "Development process improvement",
          "Tuning and scaling of scrum boards",
        ],
      },
    },
  };

  return (
    <>
      <HeroBanner
        video={cloudBanner}
        title={bannerTitle}
        description={bannerDisc}
        button={true}
      />
      <section className="">
        <DataDisplay
          title={heading}
          description={description}
          image={"/assets/svg/cloud1.svg"}
        />
      </section>
      <HorizontalScrollSectionService
        services={services}
        whyChooseTitle="Why choose Hoffensoft for Cloud Solution Services"
      />
      <div className="py-5">
        <Stack>
          <h2 className="sub-heading sub-heading-styling text-center">
            Our Cloud Process
          </h2>
          <p
            className="paragraph mt-2 mt-lg-4 mt-md-4 mt-sm-2 text-center cloud-process-desc"
            style={{ color: "#4B4B4B" }}
          >
            With over a decade of cloud expertise, our services span multiple
            platforms, optimized to deliver exceptional value to your business.
            Experience cloud solutions that drive superior performance and
            growth!
          </p>
        </Stack>
        <img
          src={width > 730 ? devProcess : devProcessMobile}
          className="img-fluid dev-process-img"
          alt={width > 700 ? "web-dev-process" : "mobile-dev-process"}
        />
      </div>

      <WhatDoWeServe
        title="What Do We Serve ?"
        description="We offer cutting-edge technology services that seamlessly blend digital artistry with your business goals. Discover a fusion of innovation and strategy designed to elevate your success!"
        data={data}
        bgImg={backImg}
        position="top center"
      />
    </>
  );
};

export default Cloud;
