// 

import React from "react";
import styled from "styled-components";
import { Container, Button } from "react-bootstrap";
import "./heroBanner.css"
import { useNavigate } from "react-router-dom";

const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 400px; 


  @media screen and (min-width:320px) and (max-width:480px){
    padding: 0% 5%;
    height:calc(430px - 5vh) !important; 

  }

  @media (max-width: 800px) {
    width: 100%; 
    height:calc(550px - 5vh)
    
  }
`;

const VideoBackground = styled.video.attrs({
  playsInline: true, // Add this attribute to ensure inline play in Safari
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;


  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(132, 71, 208, 0.8); /* Adjust the opacity as needed */
`;


const BannerContent = styled(Container)`
  position: absolute;
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%); 
  text-align: left; 
  color: #fff; 
  z-index: 1;


  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 800px) {
    top: 100px;
    transform: translate(-50%, 50px);
  }

  @media (min-width: 320px) and (max-width: 480px) {

    transform: translate(-50%, -10px);
    padding: 0% 6%;
  }
`;

const BannerHeading = styled.h1`
 
  margin-bottom: 16px; 


  @media (max-width: 768px) {

  }
  @media (min-width: 426px) and (max-width: 576px) {

    text-align: center;
  }

  @media (min-width: 320px) and (max-width: 425px) {
    text-align: center;
  }
`;

const BannerDescription = styled.p`
 
  margin-bottom: 32px; 
  max-width: 1000px;
  
  @media (max-width: 768px) {

  }
  @media (max-width: 576px) {

  }
`;

const HeroBanner = ({ video, title, description, partnerIcon, button }) => {

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(`/${path}`);
  };
  return (
    <BannerContainer>
      <VideoBackground autoPlay muted loop playsInline>
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </VideoBackground>
      <Overlay />
      <BannerContent>
        <BannerHeading className="heading text-md-left text-sm-center text-xs-center">
          {title}
        </BannerHeading>
        <BannerDescription className="paragraph">
          {description}
        </BannerDescription>
        {button && 
        <Button variant="outline" className=" hero-btn rounded-pill px-4 py-3" onClick={()=>handleNavigation("support")}>
          Start a Project
        </Button>
        }
        {partnerIcon && (
          <img className="partner-style" src={partnerIcon} alt="Partner Icon" />
        )}
      </BannerContent>
    </BannerContainer>
  );
};

export default HeroBanner;