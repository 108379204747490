import React from 'react'
import InputGroup from 'react-bootstrap/InputGroup';
import { Form } from 'react-bootstrap';
import "./input.css"

const Input = ({icon, placeholder, borderRadius, onChange}) => {
  return (
    <InputGroup className={`mb-3 ${borderRadius ? "" : "border-radius"}`}>
        <InputGroup.Text id="basic-addon1"><img src={icon ? icon : null} className='img-fluid icon' /></InputGroup.Text>
        <Form.Control
          placeholder={placeholder}
          aria-label={placeholder}
          aria-describedby="basic-addon1"
          onChange={onChange}
        />
      </InputGroup>
  )
}

export default Input