import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Support from "./pages/Support";
import Mobile from "./pages/service/Mobile";
import Blogs from "./pages/Blogs";
import WhyHS from "./pages/WhyHS";
import Careers from "./pages/Careers";
import HelpingHand from "./pages/HelpingHand";
import Cloud from "./pages/service/Cloud";
import Voice from "./pages/service/Voice";
import Analytics from "./pages/service/Analytics";
import OpenSource from "./pages/service/OpenSource";
import Devops from "./pages/service/Devops";
import BlogDetails from "./pages/BlogDetails";
import ProjectOverview from "./pages/ProjectOverview";

import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ManageServices from "./pages/service/ManageServices";


function App() {

  return (
    <div className="App">

      <Header />
      <div className="wrapper">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/support" element={<Support />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog-details/:id" element={<BlogDetails />} />
          <Route path="/why-hoffensoft" element={<WhyHS />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/helping-hand" element={<HelpingHand />} />
          <Route path="/service-cloud" element={<Cloud />} />
          <Route path="/service-voice" element={<Voice />} />
          <Route path="/service-mobile" element={<Mobile />} />
          <Route path="/service-analytics" element={<Analytics />} />
          <Route path="/service-opensource" element={<OpenSource />} />
          <Route path="/service-devops" element={<Devops />} />
          <Route path="/managed-services" element={<ManageServices />} />
          <Route path="/project-overview/:project" element={<ProjectOverview />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
