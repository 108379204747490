import React, { useEffect } from "react";
import HeroBanner from "../components/HeroBanner";
import DataDisplay from "../components/DataDisplay";
import { Col, Container, Row } from "react-bootstrap";
import CoreValueCard from "../components/CoreValueCard";
import HorizontalScrollSection from "../components/HorizontalScrollSection";
import CustomeAccordion from "../components/CustomeAccordion";

import "./about.css";

import about_us from "../assests/abous_us.mp4";
import about from "../assests/About.svg";
import leadership from "../assests/leadership.svg";

import relationship from "../assests/relationship.gif";
import work from "../assests/work.gif";
import organic from "../assests/organic.gif";
import idea from "../assests/idea.gif";

import timely_deliver from "../assests/timely_deliver.png";
import dedicate_support from "../assests/dedicate_support.png";
import transparent_com from "../assests/transparent_com.png";
import advance_tech from "../assests/advance_tech.png";
import security from "../assests/security.png";
import innovation from "../assests/innovation.png";

const About = () => {
  const bannerTitle =
    "Unlock effortless revenue growth with our innovative solutions!";
  const bannerDisc =
    "Boost your top and bottom lines effortlessly with our products, ensuring seamless operations, empowering self-service, and slashing churn!";

  const services = [
    {
      title: "Timely Delivery",
      desc: "Hoffensoft ensures that projects are delivered on time, understanding the importance of meeting deadlines.",
      icon: timely_deliver,
    },
    {
      title: "Dedicated Support",
      desc: "Hoffensoft provides round-the-clock support, ensuring that clients receive assistance whenever needed.",
      icon: dedicate_support,
    },
    {
      title: "Transparent Communication",
      desc: "Hoffensoft values clear communication, keeping clients informed at every step of the project.",
      icon: transparent_com,
    },
    {
      title: "Advanced Technology",
      desc: "Hoffensoft utilizes the latest software and analytics tools and technoloies.",
      icon: advance_tech,
    },
    {
      title: "Security and Compliance",
      desc: "Hoffensoft places a high emphasis on data security, ensuring your data is proteced.",
      icon: security,
    },
    {
      title: "Innovation",
      desc: "Hoffensoft stays abreast of the latest industry trends and incorporates innovative solutions.",
      icon: innovation,
    },
  ];

  const accodianItems = [
    {
      qus: "What makes Hoffensoft different from other software development companies?",
      ans: "Hoffensoft stands out due to our client-centric approach, custom solutions, commitment to quality, and a team of highly skilled professionals who are passionate about technology and innovation.",
    },
    {
      qus: "How does Hoffensoft determine the cost of a project?",
      ans: "The cost of a project at Hoffensoft is determined based on factors such as project complexity, required technologies, development time, and specific client needs. We provide detailed quotes after discussing the project requirements.",
    },
    {
      qus: "Why should I trust Hoffensoft?",
      ans: "You can trust Hoffensoft due to our proven track record of delivering high-quality projects and our team of skilled professionals committed to excellence. We adhere to industry best practices and prioritize client satisfaction through continuous support and updates.",
    },
    {
      qus: "How does Hoffensoft stay updated with the latest technologies? ",
      ans: "Our team participates in continuous learning through certifications, training programs, and attending industry conferences to stay updated with the latest technologies and trends.",
    },
    {
      qus: "How does Hoffensoft ensure client satisfaction?",
      ans: "Hoffensoft prioritizes client satisfaction by maintaining open communication, delivering high-quality solutions on time and within budget, and providing continuous support and improvements.",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="about-container">
      <HeroBanner
        video={about_us}
        title={bannerTitle}
        description={bannerDisc}
      />
      <DataDisplay
        title="About Us"
        color="black"
        description="Hoffensoft is a leading cloud services firm, specializing in cutting-edge enterprise solutions. We partner with our clients to craft custom applications that unlock the full potential of their resources, driving efficiency and success."
        image={about}
        className="pb-5 lr-padding"
      />
      <Container fluid style={{ backgroundColor: "#F5EDFF" }} className="py-5">
        <Container className="d-flex flex-column justify-content-center align-items-center lr-padding">
          <Row className="justify-content-center">
            <Col lg={10} md={10} sm={12} className="align-self-center">
              <h2 className="sub-heading pb-md-5 pb-sm-3 pb-xs-3 text-center core-value-heading">
              Core Values of App Development Company
              </h2>
            </Col>
          </Row>

          <Row className="gx-lg-3 gy-lg-5 gx-md-5 gy-md-5">
            <Col lg={3} md={6} sm={6} xs={12} className="core-card-container">
              <CoreValueCard
                icon={relationship}
                title="Fostering Long-Term Partnerships"
                description="We prioritize lasting relationships by delivering scalable, cost-effective solutions and comprehensive support throughout the project lifecycle, ensuring perfect alignment with your business goals and budgets."
              />
            </Col>
            <Col lg={3} md={6} sm={6} xs={12} className="core-card-container">
              <CoreValueCard
                icon={work}
                title="Emphasizing Transparency and Collaboration"
                description="We foster an environment where our employee-owners manage administrative tasks with a strong sense of responsibility, driven by transparency and collaboration to deliver cost-effective solutions."
              />
            </Col>
            <Col lg={3} md={6} sm={6} xs={12} className="core-card-container">
              <CoreValueCard
                icon={organic}
                title="Commitment to Our Society"
                description="We are committed to making a meaningful difference through employee-driven initiatives that focus on environmental sustainability and enhancing community well-being, reflecting a passion that goes beyond traditional corporate social responsibility."
              />
            </Col>
            <Col lg={3} md={6} sm={6} xs={12} className="core-card-container">
              <CoreValueCard
                icon={idea}
                title="Customized Solutions"
                description="We ensure open communication between customers and developers to deliver custom cloud solutions that optimize your enterprise technology investments."
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <HorizontalScrollSection
        title="Why choose Hoffensoft for software app services"
        services={services}
      />
      <DataDisplay
        title="Message from Our Leadership Team"
        color="black"
        description="From our humble beginnings tackling technical challenges, Hoffensoft has evolved into a dynamic powerhouse addressing major business problems. As your strategic partner, Hoffensoft is the smart choice for overcoming challenges and seizing opportunities."
        image={leadership}
        className="lr-padding"
        order={true}
      />
      <section
        className="section-padding"
        style={{ backgroundColor: "#FAF6FF" }}
      >
        <Container className="lr-padding">
          <h3 className="accordion-heading accordion-title acc text-wrap lh-sm pb-lg-5 text-center para">
            FAQs On Software & Web Application Development Services
          </h3>
          <CustomeAccordion
            btncss="customeBtn"
            itemcss="customItem"
            items={accodianItems}
          />
        </Container>
      </section>
    </div>
  );
};

export default About;
