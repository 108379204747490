import React from 'react'

import { Container, Row, Col } from 'react-bootstrap';
import Slider from 'react-slick';
import HorizontalScrollCard from './HorizontalScrollCard_Service';

const HorizontalScrollSectionService = ({ services, whyChooseTitle }) => {

    var settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        // autoplay: true,
        // autoplaySpeed: 3000,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    // dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };


    return (
        <>
            <Container className="why-choose-container" fluid>
                <Row>
                    <Col lg={3} md={12} sm={12} className="mx-auto my-auto ps-3 pe-3">
                        <h2 className="why-choose why-choose-title">
                            {whyChooseTitle}
                        </h2>
                    </Col>
                    <Col lg={9} md={12} sm={12} className="horizontal-scroll-container">
                        <Slider {...settings}>
                            {services?.map((service) =>
                                <div key={service?.title} className="horizontalScroll" >
                                    <div className="mb-0 mb-lg-0 mb-md-5 mb-sm-0 d-flex align-items-stretch card-height w-100 mx-0 mx-lg-0 mx-md-4 mx-sm-0">
                                        <HorizontalScrollCard
                                            className="horizontalScroll__cell w-100"
                                            icon={service?.icon}
                                            title={service?.title}
                                            description={service?.description}
                                        />
                                    </div>
                                </div>
                            )}
                        </Slider>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default HorizontalScrollSectionService