import React, { useEffect } from "react";
import HeroBanner from "../../components/HeroBanner";
import about_us from "../../assests/abous_us.mp4";
import DataDisplay from "../../components/DataDisplay";
import voiceImg from "../../assests/Voice/Call center.gif";
import { Stack } from "react-bootstrap";

import devProcess from "../../assests/Voice/process web view.svg";
import backImg from "../../assests/back_img.png";
import icon1 from "../../assests/Voice/why_1.svg";
import icon2 from "../../assests/Voice/why_2.svg";
import icon3 from "../../assests/Voice/why_3.svg";
import icon4 from "../../assests/Voice/why_4.svg";
import icon5 from "../../assests/Voice/why_5.svg";

import WhatDoWeServe from "../../components/WhatDoWeServe";
import devProcessMobile from "../../assests/Voice/process mobile view.svg";
import "../../components/horizontalScrollSectionService.css";
import "./voice.css";
import "./service.css";
import useWindowDimensions from "../../hooks/useWindowDimension";
import HorizontalScrollSectionService from "../../components/HorizontalScrollSectionService";

const Voice = () => {
  const services = [
    {
      icon: icon1,
      title: "Expertise with Major Platforms",
      description:
        "Hoffensoft has extensive experience in developing voice applications for major platforms like Google Assistant and Amazon Alexa. Leverage our expertise to amplify your reach and impact!",
    },
    {
      icon: icon2,
      title: "Custom Voice Solutions",
      description:
        "Hoffensoft collaborates closely with clients to understand their unique needs, crafting custom voice solutions that deliver maximum impact. Experience tailored innovation that speaks to your success!",
    },
    {
      icon: icon3,
      title: "Enhanced User Experience",
      description:
        "Hoffensoft crafts intuitive and user-friendly voice applications designed to boost user engagement and satisfaction. Elevate your user experience with our cutting-edge solutions!",
    },
    {
      icon: icon4,
      title: "Security and Compliance",
      description:
        "Hoffensoft prioritizes security, ensuring that our voice applications comply with all relevant data privacy and security standards. Trust us to keep your operations safe and compliant!",
    },
    {
      icon: icon5,
      title: "Integration and Scalability",
      description:
        "Our scalable solutions ensure your voice applications grow seamlessly with your business needs. Embrace future-proof innovation that evolves with you!",
    },
  ];

  const { width } = useWindowDimensions();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const bannerTitle = "Voice Apps";
  const bannerDisc =
    "Technology isn’t just a tool; it empowers learners to find their voices and express themselves like never before. Unleash the power of technology and be heard!";

  const heading = "Voice-enabled applications ";
  const description =
    "Voice-enabled applications let you interact with your software in the most natural way possible. Voice-based commands offer a universal interface, ensuring versatility across all operational models. These applications can be personalized for your unique business needs and enhance overall project management. Interact with your applications naturally using voice-based commands. This universal interface offers versatility across all operational models. Personalize voice applications for your business needs and enhance overall project management.";

  const data = {
    Alexa: {
      title: "Alexa",
      description: `With over six years of experience in Alexa app development, Hoffensoft has successfully created more than 25 applications centered around Alexa skills. We specialize in developing enterprise-specific applications on Alexa, seamlessly linking your organization’s accounts and operations with the platform. Elevate your business with our innovative Alexa solutions! `,
    },
    "Google Assistant": {
      title: "Google Assistant",
      description:
        "Hoffensoft offers enterprise solutions with Google Home voice apps, empowering you to develop custom Google Home applications and voice assistants tailored for your organization. We also provide seamless voice integration of Google Home with your existing tools and applications. Enhance your operations with our innovative Google Assistant solutions!",
    },
  };

  return (
    <>
      <HeroBanner
        video={about_us}
        title={bannerTitle}
        description={bannerDisc}
        button={true}
      />
      <section className="">
        <DataDisplay
          title={heading}
          description={description}
          image={voiceImg}
        />
      </section>
      <HorizontalScrollSectionService
        services={services}
        whyChooseTitle="Why choose Hoffensoft for voice App Services"
      />
      <div className="py-5">
        <Stack>
          <h2 className="sub-heading sub-heading-styling text-center">
            Our Voice Process
          </h2>
          <p
            className="paragraph mt-2 mt-lg-4 mt-md-4 mt-sm-2  text-center voice-process-desc"
            style={{ color: "#4B4B4B" }}
          >
            Experience a crystal-clear and smooth journey with our voice
            application development. We ensure a seamless process from start to
            finish, delivering exceptional results every step of the way!
          </p>
        </Stack>
        <img
          src={width > 730 ? devProcess : devProcessMobile}
          className="img-fluid dev-process-img"
          alt={width > 700 ? "web-dev-process" : "mobile-dev-process"}
        />
      </div>
      <WhatDoWeServe
        title="What Do We Serve ?"
        description="Voice-enabled applications are the next wave of tech innovation. They let you interact with your software in the most natural way possible, revolutionizing your user experience. Embrace the future of technology with us!"
        bgImg={backImg}
        data={data}
        position="top center"
      />
    </>
  );
};

export default Voice;
