import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "../components/DataDisplay.css";

const DataDisplay = ({ title, description, image, className='', order=false}) => {
  return (
    <Container className={`pt-5 ${className}`}>
      <Row className={order ? "order" : ""}>
        <Col lg={6} md={12} sm={12} className="my-auto display-top">
          <h2 className="data-display-heading pb-1 text-lg-start text-sm-center sub-heading">
            {title}
          </h2>
          <p className="data-display-para paragraph text-lg-start text-sm-center">
            {description}
          </p>
        </Col>
        <Col lg={6} md={12} sm={12} className="my-auto display-bottom">
          <img src={image} alt="data-image" className="img-fluid mx-auto d-block" />
        </Col>
      </Row>
    </Container>
  );
};

export default DataDisplay;
