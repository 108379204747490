import React, { useEffect, useState } from "react";
import "./BlogDetails.css";
import articleImage from "../assests/Blogs/BlogScreenAsset/Article_Image2.png";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";
import BlogCard from "../components/BlogCard";
import instagramIcon from "../assests/Blogs/BlogScreenAsset/Instagram.svg";
import fbIcon from "../assests/Blogs/BlogScreenAsset/Facebook.svg";
import xIcon from "../assests/Blogs/BlogScreenAsset/X.svg";
import { blogs } from "../utils/blogContents";
import { useNavigate, useParams } from "react-router-dom";

const CardTime = styled.p`
  color: #8447d0;
  margin: 0;
  font-size: 17px;
  padding-top: 50px;
  padding-bottom: 15px;
`;

const CardTitle = styled.h4`
  font-size: 22px;
  text-align: left;
  line-height: 26.63px;
  text-transform: capitalize;
`;
const BlogDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [blog, setblog] = useState({});

  const relatedBlogs = [blogs[0], blogs[1]];

  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    // Object.keys(blogs).map((item) => {
    //   if (item && item.length > 0) {
    //     const currentBlog = blogs[item]?.find((blogItem) => blogItem.id == id);
    //     if (currentBlog) {
    //       setblog(currentBlog);
    //     }
    //   }
    // });
    const currentBlog = blogs.find(blogItem => blogItem.id == id)
    if(currentBlog) {
      setblog(currentBlog)
    }
  }, [id]);

  const handleNavigation = (blog) => {
    navigate(`/blog-details/${blog.id}`);
  };

  return (
    <>
      <section>
        <img
         src={blog?.bannerImage}
          alt="blog-banner-image"
          className="img-fluid blog-banner-img"
        />
      </section>
      <section className="mb-5">
        <Container className="lr-padding">
          <Row>
            <Col lg={8} className="pe-md-5 pe-sm-1">
              <CardTime className="inter-semi-bold">
                {blog?.date ? moment(blog?.date).format("MMMM D, YYYY") : ""} |{" "}
                {/* {blog?.readingTime} */}
              </CardTime>
              <div className="d-flex justify-content-between">
                <CardTitle className="inter-semi-bold">
                  {blog?.title}
                </CardTitle>
                {/* <div className="social-media-icons">
                  <a
                    target="_blank"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${window.location}`}
                  >
                    <img src={fbIcon} alt="fb-icon" />
                  </a>
                  <a
                    target="_blank"
                    href={`https://twitter.com/intent/tweet?url=${window.location}`}
                  >
                    <img src={xIcon} alt="x-icon" />
                  </a>
                </div> */}
              </div>

              <div
                className="blog_content"
                dangerouslySetInnerHTML={{ __html: blog?.content }}
              ></div>
            </Col>
            <Col lg={4} className="ps-md-5 ps-sm-1">
              <CardTitle
                style={{ paddingBottom: "40px" }}
                className="inter-semi-bold mt-5"
              >
                Related Blogs
              </CardTitle>
              <Row>
                <BlogCard
                  blog={relatedBlogs[0]}
                  onClick={() => handleNavigation(relatedBlogs[0])}
                />
              </Row>
              <Row>
                <BlogCard
                  blog={relatedBlogs[1]}
                  onClick={() => handleNavigation(relatedBlogs[1])}
                />
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default BlogDetails;
