import { React, useEffect, useState } from "react";
import { Container, Row, Col, Tabs, Tab, Button } from "react-bootstrap";
import BlogCard from "../components/BlogCard";
import "./blogs.css";
// import about_us from "../assests/abous_us.mp4";
import BlogBanner from "../components/BlogBanner";
import { useNavigate } from "react-router-dom";
import { blogs } from "../utils/blogContents";

import NoDataFound from "../assests/Blogs/no data found.svg"

const Blogs = () => {
  const navigate = useNavigate();

  const handleNavigation = (blog) => {
    // console.log("bllllll", blog);
    navigate(`/blog-details/${blog.id}`);
  };

  const handleQueryChange = (value) => {
    setSearchQuery(value);
  };

  const tabs = ["Latest", "General", "Technology", "Case Studies", "Resources"];
  const bannerTitle = "Corporate blog";
  const bannerDisc = "Insights from our team and our culture";

  const [filteredBlogs, setFilteredBlogs] = useState(blogs);
  const [selectedCategory, setSelectedCategory] = useState(tabs[0]);
  const [searchQuery, setSearchQuery] = useState("");
  const [visibleBlogs, setVisibleBlogs] = useState(6);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    filterBlogs();
  }, [selectedCategory, searchQuery]);


  const filterBlogs = () => {
    let filtered = blogs;

    if (selectedCategory) {
      // filtered = blogs[`${selectedCategory.replace(" ", "_")}`];
      filtered = blogs?.filter((blog)=>blog?.tag?.includes(selectedCategory.toLowerCase()));
    }


    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      let filteredByQuery = [];

      filteredByQuery = filtered.filter((blog) =>
        blog.title.toLowerCase().includes(query)
      );
      filtered = filteredByQuery;
    }

    filtered.sort((a, b) => new Date(b.date) - new Date(a.date));

    setFilteredBlogs(filtered);
  };

  const handleLoadMore = () => {
    setVisibleBlogs(filteredBlogs?.length);
  };

  return (
    <div className="mb-5 blogs-section">
      <BlogBanner
        // video={about_us}
        title={bannerTitle}
        description={bannerDisc}
        style={{ paddingBottom: "20px" }}
        onSearch={handleQueryChange}
      />
      <Container className="blogs-container lr-padding">
        <Row className="mt-5 blog">
          <Col lg={12} md={12} sm={12}>
            <Tabs
              className="border-0"
              id="icon-tabs"
              onSelect={(k) => {
                setSelectedCategory(k);
                setVisibleBlogs(6);
              }}
            >
              {tabs?.map((tab, index) => (
                <Tab
                  eventKey={tab}
                  key={tab}
                  title={
                    <span className="tab_img">{tab.replace("_", " ")}</span>
                  }
                ></Tab>
              ))}
            </Tabs>
          </Col>
        </Row>
        <Row className="HomeContainer mt-4 mb-4">
          <Col lg={6} md={6} className="align-self-center">
            <h4 className="card-title text-start">{selectedCategory}</h4>
          </Col>
        </Row>
        {filteredBlogs?.length > 0 ? (
          <Row className="gx-md-5 gy-md-5">
            {filteredBlogs.slice(0, visibleBlogs).map((blog, index) => (
              <Col
                lg={4}
                md={6}
                sm={12}
                className="d-flex justify-content-center mb-lg-4 mb-sm-0"
                key={index}
              >
                <BlogCard blog={blog} onClick={() => handleNavigation(blog)} />
              </Col>
            ))}
          </Row>
        ) : (
          <Container className="no-data d-flex align-items-center justify-content-center">
            <img
              className="d-block mx-auto"
              src={NoDataFound}
              alt="no data found"
            />
          </Container>
        )}
        {visibleBlogs < filteredBlogs?.length && (
          <div className="d-flex justify-content-center load-more-button">
            <Button
              variant="outlined"
              className="btn load-more border border-dark rounded-pill px-4 py-3 inter-medium "
              onClick={handleLoadMore}
            >
              Load More{" "}
              <span className="load-more ms-2">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 0.75L7 13.25L7 0.75ZM7 13.25L13 7.25L7 13.25ZM7 13.25L1 7.25L7 13.25Z"
                    fill="#8447D0"
                  />
                  <path
                    d="M7 13.25L1 7.25M7 0.75L7 13.25L7 0.75ZM7 13.25L13 7.25L7 13.25Z"
                    stroke="#8447D0"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </Button>
          </div>
        )}
      </Container>
    </div>
  );
};
export default Blogs;
