import React from "react";
import { Container } from "react-bootstrap";
import TabNavigation from "./TabNavigation"
import "./whatDoWeServe.css"

const WhatDoWeServe = ({bgImg, title, description, data, position}) => {

  return (
    <section className="what-do-we-server-sec"
      style={{
        backgroundImage: `url(${bgImg})`,
        backgroundPosition: `${position}`
      }}
    >
      <Container className="d-flex flex-column align-items-center mb-lg-0 mb-md-5 mb-sm-5">
        <h2 className="what-do-we-serve-title sub-heading pt-0 pt-lg-4 pt-md-4 pt-sm-0 text-center">{title}</h2>
        <p className="what-do-we-serve-desc paragraph mb-5 mb-lg-1 mb-md-0 mb-sm-5 mt-3" style={{color: "#4B4B4B"}}>{description}</p>
      </Container>
      <Container className="p-lg-5 p-md-0 p-sm-0 container-width">
        <div style={{ backgroundColor: "white" }}>
          <TabNavigation data={data} />
        </div>
      </Container>
    </section>
  );
};

export default WhatDoWeServe;