import React from "react";
import styled from "styled-components";

const StyledCard = styled.div`
  width: auto;
  min-height: 490px;
  border: none;
  border-radius: 20px;
  padding: 40px 35px;
  background-color: #fff;

  @media screen and (min-width: 320px) and (max-width: 375px) {
    padding: 40px 30px;
    min-height: 300px;
  }
  @media screen and (min-width: 376px) and (max-width: 480px) {
    padding: 40px 30px;
    min-height: 300px;
  }
  @media screen and (min-width: 481px) and (max-width: 800px) {
    min-height: 480px;
}
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CardIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
`;

const CardImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
  @media screen and (min-width: 320px) and (max-width: 425px) {
    width: 90px;
    height: 90px;
  }
`;

const CardContent = styled.div`
  margin-top: 16px;
`;

const CardTitle = styled.h4`
  margin-top: 8px;
`;

const CardText = styled.p`
  margin-top: 8px;
  margin-bottom: 0px;

`;

const CoreValueCard = ({ icon, title, description }) => {
  return (
    <StyledCard>
      <CardBody>
        <CardIcon>
          <CardImage src={icon} alt="Cloud Icon" />
        </CardIcon>
        <CardContent>
          <CardTitle className="card-title">{title}</CardTitle>
          <CardText className="card-para">{description}</CardText>
        </CardContent>
      </CardBody>
    </StyledCard>
  );
};

export default CoreValueCard;
