import React, {useEffect} from "react";
import HeroBanner from "../../components/HeroBanner";
import mobileBanner from "../../assests/Services_MobileApp/top_banner.mp4";
import DataDisplay from "../../components/DataDisplay";
import { Stack } from "react-bootstrap";
import experience from "../../assests/experience.png";
import crossPlatform from "../../assests/cross_platform.png";
import userCentric from "../../assests/user_centric.png";
import devProcess from "../../assests/dev_process.svg";
import backImg from "../../assests/back_img.png"
import mobileAppImg from "../../assests/Services_MobileApp/App_dev.gif";
import agileIcon from "../../assests/Services_MobileApp/why_4.svg";
import securityIcon from "../../assests/Services_MobileApp/why_5.svg";
import partnerIcon from "../../assests/hs_px.svg";
import AndroidIcon from "../../assests/Group_532.png";
import iOSIcon from "../../assests/Group_533.png";
import HybridIcon from "../../assests/Group_531.png";
import WhatDoWeServe from "../../components/WhatDoWeServe";
import "../../components/horizontalScrollSectionService.css"
import "./mobile.css"
import useWindowDimensions from "../../hooks/useWindowDimension";
import devProcessMobile from '../../assests/Services_MobileApp/process_web.svg';
import HorizontalScrollSectionService from "../../components/HorizontalScrollSectionService";

const Mobile = () => {
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const bannerTitle = "Mobile App Development";
  const bannerDisc =
    "If Mobile Isn’t Part of Your Strategy, You’re Not Done Yet";
  const heading = "Elevate Your Business with Enterprise Mobile App Development";
  const description =
    "Harness the power of mobile to digitize your processes and enhance customer value. Our team of over 40 experts delivers cutting-edge mobile app solutions swiftly, leveraging our experience from developing over 100 apps. We’ve fine-tuned our approach to significantly boost your productivity and efficiency, driving your business forward with unparalleled innovation.";
  const data = {
    Android: {
      title: "Android",
      description: `Our expert Android app development team creates applications with a consistent UI and compatibility across all resolutions. We deliver apps designed for both online and offline use, featuring data synchronization between devices and servers, push notifications, and in-app purchases, ensuring a smooth and engaging user experience.`,
      image: AndroidIcon,
    },
    iOS: {
      title: "iOS",
      description:
        "Our iOS app development solutions are designed for ease of use, ensuring your users can seamlessly find, download, and navigate your application. We enhance functionality with features such as online/offline data synchronization, push notifications, in-app purchases, and external API integration, delivering a superior user experience.",
      image: iOSIcon,
    },
    Hybrid: {
      title: "Hybrid",
      description:
        "Hybrid apps bring together the best of both native and web worlds. Combining the seamless performance of native apps with the versatile reach of web applications, hybrid apps offer a cost-effective solution with cross-platform capabilities. They provide effortless offline functionality and easy access to device data, delivering a powerful user experience across all platforms and operating systems.",
      image: HybridIcon,
    },
  };

  const services = [
    {title: "Experience and Expertise", icon: experience, description: "Hoffensoft boasts a team of seasoned professionals proficient in mobile app development across various platforms and technologies."},
    {title: "Cross-Platform Proficiency", icon: crossPlatform, description: "Their cross-platform expertise enables you to reach a broader audience while minimizing development costs and time-to-market."},
    {title: "User-Centric Design", icon: userCentric, description: "They conduct thorough user research and usability testing to create engaging and user-friendly mobile experiences."},
    {title: "Agile Development Approach", icon: agileIcon, description: "Hoffensoft follows agile development methodologies, fostering collaboration, flexibility, and rapid iterations throughout the development lifecycle."},
    {title: "Security and Compliance", icon: securityIcon, description: "They adhere to industry best practices and regulatory standards, ensuring compliance with relevant data and privacy regulations."}
  ]

  return (
    <>
      <HeroBanner
        video={mobileBanner}
        title={bannerTitle}
        description={bannerDisc}
        partnerIcon={partnerIcon}
        button={true}
      />
      <section className="">
        <DataDisplay
          title={heading}
          description={description}
          // image={mobileAppImg}
          image={"/assets/svg/mobile.svg"}
        />
      </section>
      <HorizontalScrollSectionService services={services} whyChooseTitle="Why Choose Hoffensoft for Your Mobile App Development Needs?"/>
      <div className="py-5">
        <Stack>
          <h2 className="sub-heading sub-heading-styling text-center">
            Our Development Process
          </h2>
          <p className="paragraph mt-2 mt-lg-4 mt-md-4 mt-sm-2 text-center mobile-process-desc" style={{ color: "#4B4B4B" }}>
          We guarantee a smooth, transparent process from start to finish, ensuring a hassle-free development journey for our clients.
          </p>
        </Stack>
        <img
          src={width > 730 ? devProcess : devProcessMobile}
          className="img-fluid dev-process-img"
          alt={width > 700 ? 'web-dev-process' : 'mobile-dev-process'}
        />
      </div>
      <WhatDoWeServe
        title={"What Do We Serve?"}
        description={`We provide clear visibility into transactions and deliver an exceptional user experience, whether through native operating system technology or hybrid solutions.`}
        data={data}
        bgImg={backImg}
        position={"top center"}
      />
    </>
  );
};

export default Mobile;

