import React, { useEffect, useState } from "react";
import about_us from "../assests/abous_us.mp4";
import HeroBanner from "../components/HeroBanner";
import { Form, Col, Container, Row, Button } from "react-bootstrap";
import "./Support.css";
import Input from "../components/Input";
import DropdownSelect from "../components/DropdownSelect";
import first_icon from "../assests/Contact_us/first_icon.svg";
import company_icon from "../assests/Contact_us/company_icon.svg";
import email_icon from "../assests/Contact_us/email_icon.svg";
import phone_icon from "../assests/Contact_us/phone_icon.svg";
import query_icon from "../assests/Contact_us/query_icon.svg";
import support_icon from "../assests/Contact_us/support_icon.svg";
import visitoffice_icon from "../assests/Contact_us/visitoffice_icon.svg";
import call_icon from "../assests/Contact_us/call_icon.svg";
import message_icon from "../assests/Contact_us/message_icon.svg";

const Support = () => {

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    phone: "",
    query: ""
  })
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const handleDataChange = (data, field) =>{
    setFormData((prev)=>{
      return{ ...prev, [field]:data }
    })
  }



  console.log(formData)

  const dropdownOptions = [
    { label: "Enquiry", value: "enquiry" },
    { label: "Quote", value: "quote" },
  ];
  return (
    <div>
      <HeroBanner
        video={about_us}
        title={"What can we help you with?"}
        description={
          "Curious about our products? Have any questions? Let’s talk"
        }
      />
      <Container fluid className="py-lg-5 px-lg-0 px-sm-0 lr-padding support-main">
        {" "}
        <Row className="mx-lg-5 mx-md-3 mx-sm-0">
          {" "}
          <Col
            lg={7}
            md={12}
            sm={12}
            xs={12}
            className="mt-lg-3 mt-md-5 mt-sm-5 mb-lg-3 mb-md-3 mb-sm-5 mb-xs-5 ps-md-4 support-left"
          >
            <Row className="gx-lg-5 gx-sm-0 me-lg-5  pb-3 ">
              <Col lg={2} md={2} sm={2} xs={2} className="px-sm-0">
                <img
                  src={support_icon}
                  className="img-fluid margin"
                  alt="support-icon"
                />
              </Col>
              <Col lg={10} md={7} sm={10} xs={10}  className="bottom-border">
                <h4 className="card-title pb-1 text-start">
                  Support Enquiries
                </h4>
                <p className="card-para content">
                  Customers who are using any of our products, get in touch with
                  our dedicated product advisors.
                </p>
              </Col>
            </Row>
            <Row className="gx-lg-5 gx-sm-0 me-lg-5  pb-3">
              <Col lg={2} md={2} sm={2} xs={2} className="px-sm-0">
                <img
                  src={visitoffice_icon}
                  className="img-fluid margin"
                  alt="visit-office-icon"
                />
              </Col>
              <Col lg={10} md={7} sm={10} xs={10} className="bottom-border">
                <h4 className="card-title pb-1 text-start">Visit Our Office</h4>
                <p className="card-para content">
                  Block A1, 2nd Floor,Span Ventures SEZ,Embassy Techzone,
                  Eachanari, Coimbatore 641021, Tamilnadu, India
                </p>
              </Col>
            </Row>
            <Row className="gx-lg-5 gx-sm-0 me-lg-5  pb-3">
              <Col lg={2} md={2} sm={2} xs={2} className="px-sm-0">
                <img
                  src={call_icon}
                  className="img-fluid margin"
                  alt="call-icon-img"
                />
              </Col>
              <Col lg={10} md={7} sm={10} xs={10} className="bottom-border">
                <h4 className="card-title pb-1 text-start">
                  For information call Hoffensoft
                </h4>
                <p className="card-para content">+91 - 9047507775 </p>
                <h4 className="card-title pb-1 text-start">
                  For hiring & quires call HR
                </h4>
                <p className="card-para content">+91 - 9047507775</p>
              </Col>
            </Row>
            <Row className="gx-lg-5 gx-sm-0 me-lg-5 ">
              <Col lg={2} md={2} sm={2} xs={2} className="px-sm-0">
                <img src={message_icon} className="img-fluid margin" alt="message-icon" />
              </Col>
              <Col lg={10} md={7} sm={10} xs={10} className="bottom-border">
                <p className=" content content-black" >
                  <span className="card-title">
                    We are Passionate workaholics,
                  </span>{" "}
                  <span className="content_black_desc">
                    so don't mind if we are not picking up, mail us! We will reach
                    you soon
                  </span>
                </p>
                <p className="card-para content">contact@hoffensoft.com</p>
                <p className="card-para content" style={{ color: "#4B4B4B" }}>
                  hr@hoffensoft.com
                </p>
              </Col>
            </Row>
          </Col>
          <Col
            lg={5}
            md={12}
            sm={12}
            xs={12}
            className="form mb-lg-3 mb-md-3 mb-sm-0 mb-xs-0 support-right"
          >
            <h2 className="sub pb-4 pt-5 pt-sm-3 mx-sm-3 text-center">
              Enquire
            </h2>
            <Form className="mx-md-5 mx-lg-5">
              <Row className="gx-md-5 gx-sm-0">
                <Col lg={12} md={12} sm={12} xs={12} className="p-md-0 form-col">
                  <Row className="gx-md-5 mx-sm-0">
                    <Col
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      className="mb-3 px-sm-0 px-md-0 pe-md-3 form-col"
                    >
                      <Input
                        icon={first_icon}
                        placeholder={"First Name*"}
                        borderRadius={true}
                        onChange={(e)=>handleDataChange(e.target.value, "first_name")}
                      />
                    </Col>
                    <Col
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      className="mb-3 px-sm-0 px-md-0 form-col"
                    >
                      <Input
                        icon={first_icon}
                        placeholder={"Last Name*"}
                        borderRadius={true}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={12} md={12} className="mb-3  p-md-0 form-col">
                  <Input
                    icon={company_icon}
                    placeholder={"Company*"}
                    borderRadius={true}
                  />
                </Col>
                <Col lg={12} md={12} className="mb-3  p-md-0 form-col">
                  <Input
                    icon={email_icon}
                    placeholder={"Email*"}
                    borderRadius={true}
                  />
                </Col>
                <Col lg={12} md={12} className="mb-3  p-md-0 form-col">
                  <Input
                    icon={phone_icon}
                    placeholder={"Phone*"}
                    borderRadius={true}
                  />
                </Col>
                <Col lg={12} md={12} className="mb-3  p-md-0 form-col">
                  <DropdownSelect
                    placeholder={"Type of query"}
                    icon={query_icon}
                    options={dropdownOptions}
                    borderRadius={true}
                  />
                </Col>
                <Col lg={12} md={12} className="mb-3  p-md-0 form-col">
                  <p
                    className="text-center inter-regular"
                    style={{ fontSize: "18px" }}
                  >
                    By signing up, you agree to our
                    <span className="fw-bold">{" "}Terms & Policy</span>
                  </p>
                </Col>
                <Col lg={12} md={12} className="mb-3  p-md-0">
                  <p
                    className="text-center inter-regular"
                    style={{ fontSize: "18px" }}
                  >

                    <Button className="submit-btn btn px-md-5 py-md-3 rounded-pill inter-bold">
                      Contact us
                    </Button>
                  </p>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Support;
